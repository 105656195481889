import React, { ReactElement, useEffect } from 'react';
import { useOutletContext, Outlet } from 'react-router-dom';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import { faStar, faFolder } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutletContext } from '../layout/Layout';
import { Permission } from '../enums/permission.enum';

export default function Setup(): ReactElement {
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Setup"
					pageDescription="Configure your needed infrastructure and configuration for building data artifacts and gathering insights."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
				/>
			),
			sidebarContext: [
				{
					name: 'Setup Getting Started',
					icon: <FontAwesomeIcon icon={faStar} />,
					to: '/setup',
				},
				{
					name: 'Analytics',
					icon: <FontAwesomeIcon icon={faFolder} />,
					id: '1',
					children: [
						{
							name: 'Dimensions',
							id: 'analytics-dimensions',
							to: '/setup/analytics/dimensions',
							permission: Permission.ViewDimension,
						},
						{
							name: 'Dimension Attributes',
							id: 'analytics-dimension-attributes',
							to: '/setup/analytics/dimension-attributes',
							permission: Permission.ViewDimensionAttribute,
						},
						{
							name: 'Key Measures',
							id: 'analytics-key-measures',
							to: '/setup/analytics/key-measures',
							permission: Permission.ViewKeyMeasure,
						},
					],
				},
				{
					name: 'Integration Tools',
					icon: <FontAwesomeIcon icon={faFolder} />,
					id: 'integration-tools',
					children: [
						{
							name: 'Dimension Sources',
							id: 'integration-tools-dimension-attribute-sources',
							to: '/setup/integration-tools/dimension-sources',
							permission: Permission.ViewDimensionSource,
						},
						{
							name: 'Key Measure Sources',
							id: 'integration-tools-key-measure-sources',
							to: '/setup/integration-tools/key-measure-sources',
							permission: Permission.ViewKeyMeasureSource,
						},
						{
							name: 'ETL Source Databases',
							id: 'integration-tools-etl-source-databases',
							to: '/setup/integration-tools/etl-source-databases',
							permission: Permission.ViewEtlSourceDatabase,
						},
					],
				},
				{
					name: 'Settings',
					icon: <FontAwesomeIcon icon={faFolder} />,
					id: 'setup',
					children: [
						{
							name: 'Periods',
							id: 'setup-periods',
							to: '/setup/settings/periods',
							permission: Permission.ViewPeriod,
						},
						{
							name: 'Date Ranges',
							id: 'setup-date-ranges',
							to: '/setup/settings/date-ranges',
							permission: Permission.ViewDateRange,
						},
						{
							name: 'Unit Types',
							id: 'setup-unit-types',
							to: '/setup/settings/unit-types',
							permission: Permission.ViewUnitType,
						},
					],
				},
				{
					name: 'Security',
					icon: <FontAwesomeIcon icon={faFolder} />,
					id: 'security',
					children: [
						{
							name: 'Users',
							id: 'security-users',
							to: '/setup/security/users',
							permission: Permission.ViewUser,
						},
						{
							name: 'User Roles',
							id: 'security-roles',
							to: '/setup/security/roles',
							permission: Permission.ViewRole,
						},
						{
							name: 'User Groups',
							id: 'security-groups',
							to: '/setup/security/groups',
							permission: Permission.ViewGroup,
						},
					],
				},
				{
					name: 'System Tools',
					id: 'system-tools',
					icon: <FontAwesomeIcon icon={faFolder} />,
				},
			],
			sidebarHeaderContext: {
				header: '',
				subheader: '',
			},
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	return (
		<React.Fragment>
			<Outlet context={{ setSubSide, subNavExpanded, setSubNavExpanded }} />
		</React.Fragment>
	);
}
