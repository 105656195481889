import { Button, ExpandableSection, Text, Title } from '@patternfly/react-core';
import React, { useEffect, useState } from 'react';
import { CategoryQuestion } from '../../types/discover';
import ChartView from '../../components/charts/ChartView';
import { LambdaInsightsResponse } from '../../api/types';
import LambdaInsights from '../../components/data-builder/LambdaInsights';
import Loader from '../../components/util/Loader';

type Props = {
	question: CategoryQuestion;
};

const Question = (props: Props) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [lambdaInsights, setLambdaInsights] = useState<LambdaInsightsResponse | undefined>();
	const [showLambdaInsights, setShowLambdaInsights] = useState<boolean>(false);
	const [lambdaInsightsLoading, setLambdaInsightsLoading] = useState<boolean>(true);
	const { question } = props;

	useEffect(() => {
		setLambdaInsightsLoading(!lambdaInsightsLoading);
	}, [lambdaInsights]);

	const onToggle = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<ExpandableSection
			toggleText={question.question}
			onToggle={onToggle}
			isExpanded={isExpanded}
			isIndented
			data-testid={`question-${question.key}`}
		>
			{question.title && <Title headingLevel="h2">{question.title}</Title>}
			{question.description && <Text>{question.description}</Text>}
			{question.hasChart && (
				<div className={'chart-data'}>
					<div className={'chart-section'}>
						<ChartView
							chartId={question.chartId ?? '0'}
							allowClickNavigate
							transparentBackground={true}
							showInsights={showLambdaInsights}
							setLambdaInsights={setLambdaInsights}
						/>
					</div>

					<div className={'insights-section'}>
						{!lambdaInsights && !lambdaInsightsLoading && (
							<Button
								key="generate-insights"
								variant="primary"
								className={'generate-insights-btn'}
								onClick={() => {
									setShowLambdaInsights(true);
									setLambdaInsightsLoading(true);
								}}
							>
								Generate Insights
							</Button>
						)}

						{lambdaInsightsLoading && <Loader />}

						{lambdaInsights && !lambdaInsightsLoading && (
							<LambdaInsights
								insights={lambdaInsights}
								hasBackground={true}
							/>
						)}
					</div>
				</div>
			)}
		</ExpandableSection>
	);
};

export default Question;
