import { faTable } from '@fortawesome/pro-light-svg-icons';
import { CardTypesEnum } from '../../enums/card-types.enum';
import { CardProps } from '../../types/cards/card-props';

export const Reports: CardProps[] = [
	{
		title: 'Manager Voluntary Turnover',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isRecent: true,
		isAll: true,
	},
	{
		title: 'Engagement Survey Trends by Department',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isRecent: true,
		isAll: true,
	},
	{
		title: 'Quick Quits 0-30 Breakdown',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isRecent: true,
		isAll: true,
	},
	{
		title: 'Turnover Aggregate %',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isFavorite: true,
		isAll: true,
	},
	{
		title: 'YoY Involuntary Turnover',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isFavorite: true,
		isAll: true,
	},
	{
		title: 'Chief Level Tenure',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isRecent: true,
		isAll: true,
	},
	{
		title: 'Rolling 12 Terminations',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isFavorite: true,
		isAll: true,
	},
	{
		title: 'Department Turnover by Position Level',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isFavorite: true,
		isAll: true,
	},
	{
		title: 'Manager and Above Hires',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isFavorite: true,
		isAll: true,
	},
	{
		title: 'Tenure, Pay, and Age by Position Level',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isFavorite: true,
		isAll: true,
	},
	{
		title: 'Birthday List',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isFavorite: true,
		isAll: true,
	},
	{
		title: 'HR Outgoing Transfers',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isFavorite: true,
		isAll: true,
	},
	{
		title: 'Exit Survey Trends',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isFavorite: true,
		isAll: true,
	},
	{
		title: 'Below Management Average Pay Rates',
		type: CardTypesEnum.Report,
		url: '#',
		icon: faTable,
		isFavorite: true,
		isAll: true,
	},
];
