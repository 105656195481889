import { Recent } from '../../../api/recents/recents';
import ListCards from '../../../components/card/ListCards';
import { CardTypesEnum } from '../../../enums/card-types.enum';
import { RecentTypesEnum } from '../../../enums/recent-types.enum';
import { Reports } from '../../../helpers/page-contexts/report-context';
import { faTable } from '@fortawesome/pro-light-svg-icons';
import { CardProps } from '../../../types/cards/card-props';
import { useEffect, useState } from 'react';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import {
	AlertVariant,
	Bullseye,
	Flex,
	FlexItem,
	Grid,
	GridItem,
	Spinner,
	Tab,
	Tabs,
	TextInput,
	Title,
} from '@patternfly/react-core';
import FilterIcon from '@fortawesome/fontawesome-pro/svgs/regular/filter.svg';
import { useIsGranted } from '../../../components/user/ApplicationProvider';
import { Permission } from '../../../enums/permission.enum';
import EmptyResults from '../../../components/EmptyResults';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

type Props = {
	initialTab: number;
};

function ListReportRecentsFavorites(props: Props) {
	const { addToast } = useToast();
	const [loading, setIsLoading] = useState(false);
	const [filter, setFilter] = useState<string>('');
	const [recentCards, setRecentCards] = useState<CardProps[]>([]);
	const [favoriteCards, setFavoriteCards] = useState<CardProps[]>([]);
	const [filteredRecentCards, setFilteredRecentCards] = useState<CardProps[]>([]);
	const [filteredFavoriteCards, setFilteredFavoriteCards] = useState<CardProps[]>([]);
	const isGranted = useIsGranted();
	const hasViewRecentPerm = isGranted(Permission.ViewRecent);
	const hasViewFavouritePerm = isGranted(Permission.ViewFavorite);
	const hasEditReportPerm = isGranted(Permission.EditReport);
	const hasViewReportPerm = isGranted(Permission.ViewReport);
	const [activeTabKey, setActiveTabKey] = useState(0);

	useEffect(() => {
		if (!hasViewRecentPerm && !hasViewFavouritePerm) {
			return;
		}

		setActiveTabKey(props.initialTab);

		getReportRecents();
		getReportFavorites();
	}, [props]);

	const getReportRecents = () => {
		setIsLoading(true);
		Recent.GetAll(RecentTypesEnum.Report)
			.then((response) => {
				if (response.length) {
					const cards = response
						.map(
							(item): CardProps => ({
								id: item.entity_id,
								icon: faTable,
								type: CardTypesEnum.Report,
								url: hasEditReportPerm
									? `/report/edit/${item.entity_id}`
									: hasViewReportPerm
									? `/report/view/${item.entity_id}`
									: '',
								title: item.name ?? '',
								isRecent: true,
								timestamp: item.accessed_at,
								refreshList: getReportRecents,
							})
						)
						.slice(0, 18);
					setRecentCards(cards);
					setFilteredRecentCards(cards);
				}

				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
				addToast('There was an issue fetching recents for reports.', AlertVariant.danger);
			});
	};

	const getReportFavorites = () => {
		setFavoriteCards(Reports.filter((card) => card.isFavorite));
		setFilteredFavoriteCards(Reports.filter((card) => card.isFavorite));
	};

	const filterCards = (value: string) => {
		setFilteredRecentCards(
			recentCards.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
		);
		setFilteredFavoriteCards(
			favoriteCards.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
		);
		setFilter(value);
	};

	const handleTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setActiveTabKey(Number(tabIndex));
	};

	const header = (
		<>
			<span className="spacer-sm"></span>
			<Grid span={12}>
				<GridItem span={12}>
					<Flex direction={{ default: 'row' }}>
						<FlexItem>
							<Title headingLevel={'h1'}>Recents & Favorites</Title>
						</FlexItem>
					</Flex>
				</GridItem>
			</Grid>
			<GridItem span={3}>
				<TextInput
					value={filter}
					type="text"
					className="filter"
					customIconUrl={FilterIcon}
					placeholder="Filter"
					onChange={(value) => filterCards(value)}
					aria-label="Filter Recents"
				/>
			</GridItem>
		</>
	);

	const emptyTemplate = (
		<EmptyResults
			emptyStateBody=""
			iconName={faInfoCircle}
			backComponent={<></>}
		/>
	);

	if (loading) {
		return (
			<Bullseye>
				<div>
					<Spinner
						isSVG
						size={'xl'}
					/>
				</div>
			</Bullseye>
		);
	}

	return (
		<>
			<Grid hasGutter>
				{header}
				<GridItem span={12}>
					<Tabs
						className="tabs"
						activeKey={activeTabKey}
						onSelect={handleTabSelect}
					>
						{hasViewRecentPerm && (
							<Tab
								eventKey={0}
								title={
									<span
										dangerouslySetInnerHTML={{
											__html: '<strong>Recents<strong/>',
										}}
									></span>
								}
							>
								{filteredRecentCards.length ? (
									<ListCards
										additionalClassNames="scrollable-cards"
										items={filteredRecentCards}
									/>
								) : (
									emptyTemplate
								)}
							</Tab>
						)}

						{hasViewFavouritePerm && (
							<Tab
								eventKey={1}
								title={
									<span
										dangerouslySetInnerHTML={{
											__html: '<strong>Favorites<strong/>',
										}}
									></span>
								}
							>
								{filteredFavoriteCards.length ? (
									<ListCards
										additionalClassNames="scrollable-cards"
										items={filteredFavoriteCards}
									/>
								) : (
									emptyTemplate
								)}
							</Tab>
						)}
					</Tabs>
				</GridItem>
			</Grid>
		</>
	);
}

export default ListReportRecentsFavorites;
