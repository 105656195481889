import React, { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';
import {
	formatCsvColumn,
	formatCsvDataToColumns,
	MultipartResponse,
} from '../../helpers/multipart-response.helper';
import { DataframeDataRetrievalResponse, TDataframeOrder, TDateRange } from '../../api/types';
import { TableComposable, Tbody, Td, Th, Thead, ThProps, Tr } from '@patternfly/react-table';
import { TNewDataframeOrder } from '../../api/dataframes/Dataframes';
import { SortTypes } from '../../types/common/sort-types';
import Loader from '../util/Loader';
import { Bullseye, EmptyState, EmptyStateVariant, Title } from '@patternfly/react-core';
import { TUnitType } from '../../api/analytics/UnitType';
import { IIndexable } from '../../types/general';
import _ from 'lodash';
import { DraggableMenuItemData } from '../../types/databuilder/databuilder';
import { DateRange } from '../../api/date-period-selector/DateRange';
import { useApplication } from '../../../src/components/user/ApplicationProvider';
import { OptionsBuilderItemTypes } from '../../types/dataframes/options-builder-item-types';
import {
	findKeyInNestedObject,
	getColumnNameByGroupKey,
	getFormattedTotalsObject,
	getPath,
	getValueByKey,
	getValueFromNestedObject,
	removeLastKey,
} from './preview-helper';
import './Preview.scss';
import { numberFormatter } from '../../helpers/number-formatter';

export type IPreviewProps = {
	data?: MultipartResponse<DataframeDataRetrievalResponse>;
	facts?: DraggableMenuItemData[];
	rows?: DraggableMenuItemData[];
	columns?: DraggableMenuItemData[];
	unitTypes?: TUnitType[];
	order?: TNewDataframeOrder | undefined;
	setOrder?: Dispatch<SetStateAction<TDataframeOrder | TNewDataframeOrder | undefined>>;
	isLoading: boolean;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	setSelectedUnitType?: Dispatch<SetStateAction<TUnitType | undefined>>;
	ApplyDrilldownDrillIn?: (name: string, isKMF: boolean) => void;
};

const Preview = (props: IPreviewProps): ReactElement => {
	const [columns, setColumns] = React.useState<string[]>([]);
	const [formattedData, setFormattedData] = React.useState<IIndexable[]>([]);
	const [unformattedData, setUnformattedData] = React.useState<IIndexable[]>([]);
	const [activeSortIndex, setActiveSortIndex] = React.useState(0);
	const [activeSortDirection, setActiveSortDirection] = React.useState<
		'asc' | 'desc' | undefined
	>('asc');
	const { user, currentDatePeriods } = useApplication();
	const defaultDatePeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);

	const previousSelectedStartPeriod = localStorage.getItem('currentSelectedStartPeriod');
	const previousSelectedEndPeriod = localStorage.getItem('currentSelectedEndPeriod');
	const hasPreviouslySelectedPeriod =
		previousSelectedStartPeriod != null || previousSelectedEndPeriod != null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedStartDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedStartPeriod
			? JSON.parse(previousSelectedStartPeriod)
			: null;
	const periodId =
		hasPreviouslySelectedPeriod && previouslySelectedStartDate
			? previouslySelectedStartDate.period
			: defaultDatePeriod?.period ?? 0;
	const [hasTotals, setHasTotals] = React.useState<boolean>(false);
	const [pivotRows, setPivotRows] = React.useState<JSX.Element[]>([]);

	useEffect(() => {
		if (props.data) {
			if (props.data.json) {
				let columns = props.data.json.columns;
				const hasTotals = props.data.json.totals ? true : false;
				setHasTotals(hasTotals);

				if (props.data.csvData) {
					let formattedData: IIndexable[] = formatCsvDataToColumns(
						props.data.json.columns,
						props.data.csvData,
						periodId
					);

					if (formattedData) {
						setUnformattedData(_.cloneDeep(formattedData));

						formattedData = formattedData.map((item: IIndexable): IIndexable => {
							const keys: string[] = Object.keys(item);
							keys.map((key) => {
								const fact = props.facts?.find((fact) => {
									const keyToUse = key.toLowerCase().replace(/\s/g, '');
									const isAlias =
										keyToUse ===
										fact.data?.alias?.toLowerCase().replace(/\s/g, '');
									const isTitle =
										keyToUse ===
										fact.data?.title.toLowerCase().replace(/\s/g, '');
									return isAlias || isTitle;
								});

								if (fact) {
									const unitType: TUnitType | undefined = props.unitTypes
										? props.unitTypes?.find(
												(type: TUnitType) => type.id == fact?.data?.unitType
										  )
										: undefined;
									if (item[key] && item[key] != '') {
										if (!isNaN(item[key] as number)) {
											if (unitType) {
												if (unitType.name.toLowerCase() === 'percentage') {
													item[key] = numberFormatter(
														parseFloat(item[key] as string) * 100,
														fact.data?.numDecimals
													);
												} else {
													item[key] = numberFormatter(
														parseFloat(item[key] as string),
														fact.data?.numDecimals
													);
												}

												item[key] = unitType.prefix
													? unitType.prefix + (item[key] as string)
													: unitType.suffix
													? (item[key] as string) + unitType.suffix
													: (item[key] as string);
											}
										}
									} else {
										if (fact.data?.defaultWhenNull) {
											item[key] = fact.data?.defaultWhenNull;
										}
									}
								} else if (key.includes('PercentofTotal')) {
									if (!isNaN(item[key] as number)) {
										item[key] = `${numberFormatter(item[key] as number)}%`;
									}
								}
							});

							return item;
						});

						if (props.columns && props.columns.length) {
							const keysToRemove: string[] = [];
							const rows: JSX.Element[] = [];

							// Build additional rows of colun headers
							props.columns.forEach((col, colIndex) => {
								if (col.data) {
									const headers: JSX.Element[] = [
										<Th colSpan={props.rows ? props.rows.length : 1}>
											{col.data.title}
										</Th>,
									];
									const currentKey = col.data.title.replace(/\s+/g, '');
									const currentUniqueColValues: string[] =
										getUniqueColumnValuesByKey(
											currentKey,
											formattedData
										) as string[];
									let previousKey: string | undefined = undefined;
									let previousUniqueValues: string[] | null = null;
									let nextKey: string | undefined = undefined;
									let nextUniqueValues: string[] | null = null;

									if (props.columns) {
										if (colIndex > 0) {
											previousKey = props.columns[
												colIndex - 1
											].data?.title.replace(/\s+/g, '');
											previousUniqueValues = getUniqueColumnValuesByKey(
												previousKey!,
												formattedData
											) as string[];
										}

										if (props.columns[colIndex + 1]) {
											nextKey = props.columns[
												colIndex + 1
											].data?.title.replace(/\s+/g, '');
											nextUniqueValues = getUniqueColumnValuesByKey(
												nextKey!,
												formattedData
											);
										}
									}

									if (previousUniqueValues && previousUniqueValues.length > 1) {
										previousUniqueValues.forEach((prevVal) => {
											currentUniqueColValues.forEach((val) => {
												headers.push(
													<Th
														colSpan={
															nextUniqueValues
																? nextUniqueValues.length
																: 1
														}
													>
														{val}
													</Th>
												);
											});
										});
									} else {
										currentUniqueColValues.forEach((val) => {
											headers.push(
												<Th
													colSpan={
														nextUniqueValues
															? nextUniqueValues.length
															: 1
													}
												>
													{val}
												</Th>
											);
										});
									}

									rows.push(<Tr>{headers}</Tr>);

									keysToRemove.push(col.data.title);
								}
							});

							// All facts need to be added to the removal list, they will be added back later
							props.facts?.forEach((fact) => {
								if (fact.data) {
									keysToRemove.push(fact.data.title);
								}
							});

							// Ensure that the dataframe columns are not part of the original columns for the table as
							// they no longer need to render as part of the original column headers
							keysToRemove.forEach((key) => {
								columns = columns.filter((colItem) => colItem != key);
							});

							const pivotedData = getPivotedData(
								formattedData,
								props.columns.map(
									(col) => col.data?.title.replace(/\s+/g, '') ?? ''
								),
								props.facts
									? props.facts.map(
											(fact) => fact.data?.title.replace(/\s+/g, '') ?? ''
									  )
									: [],
								props.rows
									? props.rows
											.filter(
												(row) =>
													row.data &&
													!keysToRemove.includes(
														row.data.title.replace(/\s+/g, '')
													)
											)
											.map((row) => row.data?.title.replace(/\s+/g, '') ?? '')
									: []
							);

							if (pivotedData.length) {
								// Calculate how many additional columns are required for all facts on the dataframe
								let colsToAdd = 0;

								props.columns.forEach((col) => {
									if (col.data) {
										const uniqueValues = getUniqueColumnValuesByKey(
											col.data.title.replace(/\s+/g, ''),
											formattedData
										);

										if (uniqueValues.length) {
											if (colsToAdd === 0) {
												colsToAdd = uniqueValues.length;
											} else {
												colsToAdd = colsToAdd * uniqueValues.length;
											}
										}
									}
								});

								for (let x = 0; x < colsToAdd; x++) {
									if (props.facts) {
										props.facts.forEach((fact) => {
											if (fact.data) {
												columns.push(fact.data.title);
											}
										});
									}
								}
							}

							setFormattedData(
								hasTotals
									? setupGroupedData(groupBy(pivotedData, getGroupByKeys()))
									: pivotedData
							);
							setPivotRows(rows);
						} else {
							setFormattedData(
								hasTotals
									? setupGroupedData(groupBy(formattedData, getGroupByKeys()))
									: formattedData
							);
							setPivotRows([]);
						}

						setColumns(columns);
					}
				} else {
					setFormattedData([]);
				}

				if (props.order) {
					let item = props.facts?.find((fact) => {
						const factDataId = fact.data?.id ?? 0;
						return (
							fact.data &&
							factDataId == props.order?.entity_id &&
							fact.entityType == props.order?.entity_type
						);
					});

					if (!item) {
						item = props.rows?.find((row) => {
							return (
								row.data &&
								row.data.id == props.order?.entity_id &&
								row.entityType == props.order?.entity_type
							);
						});
					}
					setActiveSortDirection(
						props.order.direction === SortTypes.Asc ? 'asc' : 'desc'
					);

					const title =
						item?.data?.title === 'Date Range' ? 'Date Series' : item?.data?.title;

					setActiveSortIndex(
						props.data.json.columns.findIndex((col: any) => col === title)
					);
				}
			}
		}
	}, [props.data, props.setSelectedUnitType]);

	useEffect(() => {
		setOrderObject();
	}, [activeSortIndex, activeSortDirection]);

	const getPivotedData = (
		data: IIndexable<any>[],
		pivotKeys: string[],
		valueKeys: string[],
		groupKeys: string[]
	): IIndexable<any>[] => {
		// Initialize the result array
		const result: IIndexable<any>[] = [];

		// Iterate over each entry in the input array to determine all unique pivot combinations
		const pivotKeyCombinations = getAllCombinations(data, pivotKeys);

		// Iterate over each entry in the input array
		data.forEach((entry) => {
			// Find or create a record in the result array
			let record = result.find((r) => {
				return pivotKeys.every((key) => r[key] === entry[key]);
			});

			if (!record) {
				// If the record doesn't exist, create it
				record = { ...entry } as IIndexable<any>;
				pivotKeys.forEach((key) => {
					delete record![key];
				});
				valueKeys.forEach((valueKey) => {
					delete record![valueKey];
				});
				// Initialize all pivot key combinations with null
				pivotKeyCombinations.forEach((pivotKey) => {
					valueKeys.forEach((valueKey) => {
						if (props.facts) {
							props.facts.forEach((fact) => {
								if (fact.data) {
									if (valueKey === fact.data.title.replace(/\s+/g, '')) {
										record![`${pivotKey}_${valueKey}`] =
											fact.data.defaultWhenNull;
									}
								}
							});
						}
					});
				});

				// Check if there already exists an item in the results array with the same values for all non pivoted columns
				const exists = result.find((f) => {
					const matches = groupKeys.every(
						(groupKey) => record && f[groupKey] === record[groupKey]
					);
					return matches;
				});

				// If and item in the results array with the same values for all non pivoted columns exists then replace
				// the current record object so that the correct columns can be set at the bottom of this function
				if (exists) {
					record = exists;
				} else {
					result.push(record);
				}
			}

			// Add pivoted values to the record
			const pivotKey = pivotKeys.map((key) => entry[key] as string).join('_');
			valueKeys.forEach((valueKey) => {
				record![`${pivotKey}_${valueKey}`] = entry[valueKey] as string;
			});
		});

		return result;
	};

	// Builds up a list of unique key combinations that is used for the pivoted data
	function getAllCombinations<T>(data: T[], keys: (keyof T)[]): string[] {
		const uniqueValues = keys.map((key) => {
			const values = new Set<string>();
			data.forEach((entry) => values.add(entry[key] as unknown as string));
			return Array.from(values);
		});

		function combine(arr: string[][], prefix: string[] = []): string[][] {
			if (!arr.length) return [prefix];
			const [first, ...rest] = arr;
			return first.flatMap((value) => combine(rest, [...prefix, value]));
		}

		const combinations = combine(uniqueValues);
		return Array.from(new Set(combinations)).map((comb) => comb.join('_'));
	}

	const getUniqueColumnValuesByKey = <T, K extends keyof T>(key: K, array: T[]): T[K][] => {
		const uniqueValues = new Set<T[K]>();
		array.forEach((item) => uniqueValues.add(item[key]));
		return Array.from(uniqueValues);
	};

	const groupBy = (array: IIndexable[], keys: string[]): IIndexable => {
		const groups: IIndexable = {};

		array.forEach((item) => {
			let group = groups;
			keys.forEach((prop) => {
				const key = String(item[prop]);
				if (!group[key]) {
					group[key] = {};
				}
				group = group[key] as IIndexable;
			});
			group['items'] = (group['items'] as IIndexable[]) || [];
			(group['items'] as IIndexable[]).push(item);
		});

		return groups;
	};

	const getGroupByKeys = () => {
		const keys: string[] = [];
		let groupObects: DraggableMenuItemData[] = [];

		const rows = props.rows?.filter((row) => row.data?.totaled);
		const columns = props.columns?.filter((column) => column.data?.totaled);

		if (rows?.length) {
			groupObects = rows;
		}

		if (columns?.length) {
			groupObects = [...groupObects, ...columns];
		}

		groupObects.forEach((item) => {
			const key = item.data?.title.replace(' ', '');
			if (key && !keys.includes(key)) {
				keys.push(key);
			}
		});

		return keys;
	};

	const getSortParams = (columnIndex: number): ThProps['sort'] => ({
		sortBy: {
			index: activeSortIndex,
			direction: activeSortDirection,
			defaultDirection: 'asc',
		},
		onSort: (event: React.MouseEvent, index: number, direction: 'asc' | 'desc' | undefined) => {
			setActiveSortIndex(index);

			//fix for if the previous sort dir is the same as the requested sort dir set to opposite dir
			if (direction?.toUpperCase() == props.order?.direction) {
				direction = direction === 'asc' ? 'desc' : 'asc';
			}
			setActiveSortDirection(direction);
		},
		columnIndex,
	});

	const filterKeysToExclude = (item: IIndexable, keysToExclude: string[]): string[] => {
		const keys = Object.keys(item);
		return keys.map((key) => {
			return (keysToExclude &&
				keysToExclude?.includes(item[key] as string) &&
				item[key]) as string;
		});
	};

	const setupGroupedData = (groupedData: IIndexable) => {
		const returnArray: IIndexable[] = [];

		if (_.isArray(groupedData)) {
			return [];
		}

		const groupedObjects = getFormattedGroupedObjects(groupedData, [], true);
		const groupedObjectsKeys = Object.keys(groupedObjects);

		// Handle top level sorting
		if (activeSortIndex === 0) {
			if (activeSortDirection === 'asc') {
				groupedObjectsKeys.sort();
			} else {
				groupedObjectsKeys.sort((a, b) => b.localeCompare(a));
			}
		} else {
			groupedObjectsKeys.sort();
		}

		groupedObjectsKeys.forEach((key: string) => {
			const { items, keysToExclude } = groupedObjects[key] as IIndexable;
			let activeFilter: string[] = [];
			if (items) {
				(items as IIndexable[]).forEach((item: IIndexable) => {
					const row = getFormattedGroupObject(item, activeFilter);
					activeFilter = filterKeysToExclude(item, keysToExclude as string[]);
					returnArray.push(row);
				});
			}
		});

		return setupGroupTotals(returnArray);
	};

	const getFormattedGroupedObjects = (
		formattedGroupData: IIndexable,
		keysToExclude: string[],
		firstRecord: boolean
	): IIndexable => {
		const keys = Object.keys(formattedGroupData);
		return keys.reduce(
			(initial: IIndexable, item) => {
				let HasKey = undefined;
				let HasNewKeysToExclude = keysToExclude;
				if (keysToExclude && keysToExclude.length === 0) {
					HasKey = true;
					HasNewKeysToExclude = [];
				}

				HasNewKeysToExclude = [...keysToExclude, ...[item]];
				if (
					formattedGroupData &&
					formattedGroupData[item] &&
					(formattedGroupData[item] as IIndexable).items
				) {
					if (!initial['items']) {
						initial['items'] = [];
					}

					if (HasKey) {
						initial[item] = {
							items: (formattedGroupData[item] as IIndexable).items as IIndexable[],
							keysToExclude: HasNewKeysToExclude,
						};
					} else {
						initial = {
							items: [
								...(initial.items as IIndexable[]),
								...((formattedGroupData[item] as IIndexable).items as IIndexable[]),
							],
							keysToExclude: [
								...(initial.keysToExclude as string[]),
								...HasNewKeysToExclude,
							],
						};
					}

					return initial;
				}

				firstRecord = false;

				const data = getFormattedGroupedObjects(
					formattedGroupData[item] as IIndexable,
					HasNewKeysToExclude,
					firstRecord
				);

				if (HasKey) {
					initial[item] = {
						items: data.items as IIndexable[],
						keysToExclude: data.keysToExclude as string[],
					};
				} else {
					initial = {
						items: [
							...(initial.items as IIndexable[]),
							...(data.items as IIndexable[]),
						],
						keysToExclude: [
							...(initial.keysToExclude as string[]),
							...(data.keysToExclude as string[]),
						],
					};
				}

				return initial;
			},
			{ items: [], keysToExclude: [] }
		);
	};

	const getFormattedGroupObject = (data: IIndexable, keysToExclude?: string[]) => {
		const keys = Object.keys(data);
		const obj: IIndexable = {};

		const excluded: string[] = [];

		for (let i = 0; i < keys.length; i++) {
			if (
				keysToExclude &&
				keysToExclude?.includes(data[keys[i]] as string) &&
				data[keys[i]]
			) {
				excluded.push(data[keys[i]] as string);
			} else {
				break;
			}
		}

		keys.forEach((key) => {
			obj[key] =
				excluded && excluded?.includes(data[key] as string) ? '' : (data[key] as string);
		});

		return obj;
	};

	const setupGroupTotals = (data: IIndexable[]) => {
		let listToReturn: IIndexable[] = [];
		if (props.data && props.data.json) {
			// Format totals based on unit type and chosen decimal places
			let totals = getFormattedTotalsObject(
				_.cloneDeep(props.data.json.totals),
				props.unitTypes!,
				props.facts!
			);

			// If the limit has been reached remove the last item so to not have missing records display on the UI
			if (
				props.data &&
				props.data.json &&
				props.data.json.result_size &&
				props.data?.json?.result_size?.count > 500
			) {
				totals = removeLastKey(totals);
			}

			Object.keys(totals).forEach((key) => {
				let groupList: IIndexable[] = [];
				const totalObject = totals[key] as IIndexable;
				let currentKey = '';
				let foundIndex = 0;

				data.forEach((dataItem, index) => {
					// Separate the data into individual groupings so totals can be applied per grouping
					const rowKeys = Object.keys(dataItem);
					for (let x = 0; x < rowKeys.length; x++) {
						const rowItem: string = currentKey.length
							? (dataItem[currentKey] as string)
							: (dataItem[rowKeys[x]] as string);

						if (rowItem === key) {
							groupList.push(dataItem);
							foundIndex = index;
							currentKey = rowKeys[x];
							break;
						} else if (rowItem === '' && index > foundIndex && currentKey.length) {
							groupList.push(dataItem);
							break;
						} else if (rowItem != key && rowItem != '') {
							foundIndex = 0;
							currentKey = '';
						}
					}
				});

				if (totalObject) {
					groupList = getTotaledGroupList(groupList, totalObject);
				}

				listToReturn = [...listToReturn, ...groupList];
			});
		}

		return listToReturn;
	};

	const getTotaledGroupList = (list: IIndexable[], totalObject: IIndexable) => {
		const clonedRow = _.cloneDeep(list[0]);
		const groupKeys = getGroupByKeys();

		if (clonedRow) {
			// Array is reversed in order to populate totals from the bottom up
			groupKeys.reverse().forEach((groupKey, groupKeyIndex) => {
				// Last total needs to be acquired by identofying the path based on the amount of rows and/or columns
				// Based on the amount of rows and/or columns we can determine the depth of the "ALL" keys in the totals object
				if (groupKeyIndex === groupKeys.length - 1) {
					Object.keys(clonedRow).forEach((cloneKey, index) => {
						if (index === Object.keys(clonedRow).length - 1) {
							clonedRow[cloneKey] = (
								<b>
									{getValueFromNestedObject(
										totalObject,
										getPath(
											props.rows?.length ?? 0,
											props.columns ? props.columns.length : undefined
										)
									)}
								</b>
							);
						} else if (cloneKey === groupKey) {
							clonedRow[cloneKey] = (
								<b>{`${
									props.data && props.data.json ? props.data.json.columns[0] : ''
								} Summary`}</b>
							);
						} else {
							clonedRow[cloneKey] = ``;
						}
					});
					list = [...list, ...[clonedRow]];
				} else {
					const groupIndexes: number[] = [];
					const rowsToAdd: IIndexable[] = [];

					list.forEach((item, index) => {
						if (item[groupKey] != '') {
							groupIndexes.push(index);
						}
					});

					groupIndexes.forEach((index, groupIndex) => {
						rowsToAdd.push({
							index: groupIndexes[groupIndex + 1]
								? groupIndexes[groupIndex + 1]
								: list.length,
							row: getTotaledRow(_.cloneDeep(list[index]), groupKey, totalObject),
						});
					});

					rowsToAdd.forEach((row, rowIndex) => {
						if (rowIndex === 0) {
							list.splice(row.index as number, 0, row.row as IIndexable);
						} else {
							list.splice((row.index as number) + rowIndex, 0, row.row as IIndexable);
						}
					});
				}
			});
		}

		return list;
	};

	const getTotaledRow = (row: IIndexable, key: string, totalObject: IIndexable) => {
		const groupText = row[key] as string;
		Object.keys(row).forEach((cloneKey, index) => {
			if (index === Object.keys(row).length - 1) {
				row[cloneKey] = (
					<b>{getValueByKey(findKeyInNestedObject(totalObject, groupText), 'value')!}</b>
				);
			} else if (cloneKey === key) {
				row[cloneKey] = (
					<b>{`${getColumnNameByGroupKey(
						key,
						props.data?.json?.columns ?? []
					)} Summary`}</b>
				);
			} else {
				row[cloneKey] = ``;
			}
		});

		return row;
	};

	const setOrderObject = () => {
		if (props.setOrder) {
			let item = props.facts?.find((fact) => {
				return fact.data?.title === columns[activeSortIndex];
			});

			if (!item) {
				item = props.rows?.find((row) => {
					return row.data?.title === columns[activeSortIndex];
				});

				if (!item) {
					item = props.rows?.find((row) => {
						return row.entityType == OptionsBuilderItemTypes.DateSeries;
					});
				}
			}

			if (
				item &&
				(props.order?.direction !== activeSortDirection?.toUpperCase() ||
					item.data?.id !== props.order?.entity_id)
			) {
				const newOrder = { ...props.order } as TNewDataframeOrder;
				newOrder.direction = activeSortDirection?.toUpperCase() ?? '';
				newOrder.entity_id = item.data?.id ? +item.data.id : 0;
				newOrder.entity_type = item.entityType;
				newOrder.useSequence = item.data?.useSequence ?? false;
				props.setOrder(newOrder);
			}
		}
	};

	const noData = (
		<Tr>
			<Td colSpan={columns.length}>
				<Bullseye>
					<EmptyState variant={EmptyStateVariant.small}>
						<Title
							headingLevel="h2"
							size="lg"
						>
							No results found
						</Title>
					</EmptyState>
				</Bullseye>
			</Td>
		</Tr>
	);

	const getColumns = () => {
		return columns.map((col, index) => {
			const isPercentageCol = col.replace(/\s+/g, '').includes('PercentofTotal');
			const sortParams = getSortParams(index);
			if (pivotRows.length && props.rows) {
				if (index > props.rows.length - 1) {
					return <Th>{col}</Th>;
				}
			}
			return isPercentageCol ? <Th>{col}</Th> : <Th sort={sortParams}>{col}</Th>;
		});
	};

	const getCells = (row: IIndexable) => {
		return columns.map((col, index) => {
			return (
				<Td
					dataLabel={col}
					className={`${props.ApplyDrilldownDrillIn ? 'cursor' : ''}`}
					onClick={() => {
						applyTableDrilldown(
							row[formatCsvColumn(col)] as string,
							props.facts && props.facts[0].data?.title == col
						);
					}}
				>
					{pivotRows.length ? row[Object.keys(row)[index]] : row[formatCsvColumn(col)]}
				</Td>
			);
		});
	};

	const applyTableDrilldown = (filterValue: string, isKMF = false) => {
		props.ApplyDrilldownDrillIn && props.ApplyDrilldownDrillIn(filterValue, isKMF);
	};

	return (
		<div className="preview-table-container">
			{props.isLoading ? (
				<Loader />
			) : (
				<TableComposable>
					<Thead className="sticky-header">
						{pivotRows}
						<Tr>{getColumns()}</Tr>
					</Thead>
					<Tbody>
						{formattedData.length > 0
							? formattedData.map((row, rowIndex) => {
									return <Tr key={rowIndex}>{getCells(row)}</Tr>;
							  })
							: noData}
					</Tbody>
				</TableComposable>
			)}
		</div>
	);
};

export default Preview;
