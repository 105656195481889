export enum Permission {
	CanViewDiscoverPage = 'canViewDiscoverPage',
	CanViewAnalyzePage = 'canViewAnalyzePage',
	CanViewReportPage = 'canViewReportPage',
	CanViewSettingsPage = 'canViewSettingsPage',
	CanViewPresentPage = 'canViewPresentPage',
	CanViewAppsPage = 'canViewAppsPage',
	CanClearPasswordHistory = 'canClearPasswordHistory',
	CanImportData = 'canImportData',
	CanPurgeData = 'canPurgeData',
	CanResetPassword = 'canResetPassword',
	CanResetUserPassword = 'canResetUserPassword',
	CanTriggerDataImport = 'canTriggerDataImport',
	CanTriggerSchemaUpdate = 'canTriggerSchemaUpdate',
	CanUploadData = 'canUploadData',
	CreateChart = 'createChart',
	CreateDashboard = 'createDashboard',
	CreateDataframe = 'createDataframe',
	CreateDateRange = 'createDateRange',
	CreateDimension = 'createDimension',
	CreateDimensionAttribute = 'createDimensionAttribute',
	CreateDimensionSource = 'createDimensionSource',
	CreateEtlSourceDatabase = 'createEtlSourceDatabase',
	CreateFolder = 'createFolder',
	CreateGroup = 'createGroup',
	CreateKeyMeasure = 'createKeyMeasure',
	CreateKeyMeasureFact = 'createKeyMeasureFact',
	CreateKeyMeasureSource = 'createKeyMeasureSource',
	CreatePeriod = 'createPeriod',
	CreateReport = 'createReport',
	CreateRole = 'createRole',
	CreateSchedule = 'createSchedule',
	CreateUnitType = 'createUnitType',
	CreateUser = 'createUser',
	CreateQuestion = 'createQuestion',
	CreateQuestionCategory = 'createQuestionCategory',
	DeleteDataframe = 'deleteDataframe',
	DeleteDateRange = 'deleteDateRange',
	DeleteDimension = 'deleteDimension',
	DeleteFolder = 'deleteFolder',
	DeleteDimensionAttribute = 'deleteDimensionAttribute',
	DeleteDimensionSource = 'deleteDimensionSource',
	DeleteEtlSourceDatabase = 'deleteEtlSourceDatabase',
	DeleteGroup = 'deleteGroup',
	DeleteKeyMeasure = 'deleteKeyMeasure',
	DeleteKeyMeasureFact = 'deleteKeyMeasureFact',
	DeleteKeyMeasureSource = 'deleteKeyMeasureSource',
	DeletePeriod = 'deletePeriod',
	DeleteRole = 'deleteRole',
	DeleteUnitType = 'deleteUnitType',
	DeleteUser = 'deleteUser',
	DeleteQuestion = 'deleteQuestion',
	EditChart = 'editChart',
	EditDashboard = 'editDashboard',
	EditDataframe = 'editDataframe',
	EditDateRange = 'editDateRange',
	EditDimension = 'editDimension',
	EditDimensionAttribute = 'editDimensionAttribute',
	EditDimensionSource = 'editDimensionSource',
	EditEtlSourceDatabase = 'editETLSourceDatabase',
	EditFavorite = 'editFavorite',
	EditFolder = 'editFolder',
	EditGroup = 'editGroup',
	EditGroupSecurityRule = 'editGroupSecurityRule',
	EditKeyMeasure = 'editKeyMeasure',
	EditKeyMeasureFact = 'editKeyMeasureFact',
	EditKeyMeasureSource = 'editKeyMeasureSource',
	EditPeriod = 'editPeriod',
	EditPermission = 'editPermission',
	EditPreference = 'editPreference',
	EditPreferenceCategory = 'editPreferenceCategory',
	EditPreferenceDefinition = 'editPreferenceDefinition',
	EditQuestion = 'editQuestion',
	EditQuestionCategory = 'editQuestionCategory',
	EditRecent = 'editRecent',
	EditReport = 'editReport',
	EditReportSchedule = 'editReportSchedule',
	EditRole = 'editRole',
	EditTag = 'editTag',
	EditUnitType = 'editUnitType',
	EditUser = 'editUser',
	EditZoneNotification = 'editZoneNotification',
	EditZoneSettings = 'editZoneSettings',
	EditSharedEntity = 'editSharedEntity',
	ViewActiveSessions = 'viewActiveSessions',
	ViewChart = 'viewChart',
	ViewDashboard = 'viewDashboard',
	ViewDataframe = 'viewDataframe',
	ViewDateRange = 'viewDateRange',
	ViewDimension = 'viewDimension',
	ViewDimensionAttribute = 'viewDimensionAttribute',
	ViewDimensionSource = 'viewDimensionSource',
	ViewEtlSourceDatabase = 'viewETLSourceDatabase',
	ViewFavorite = 'viewFavorite',
	ViewFolder = 'viewFolder',
	ViewGroup = 'viewGroup',
	ViewGroupSecurityRule = 'viewGroupSecurityRule',
	ViewKeyMeasure = 'viewKeyMeasure',
	ViewKeyMeasureFact = 'viewKeyMeasureFact',
	ViewKeyMeasureSource = 'viewKeyMeasureSource',
	ViewLoginActivity = 'viewLoginActivity',
	ViewPeriod = 'viewPeriod',
	ViewPermission = 'viewPermission',
	ViewPreference = 'viewPreference',
	ViewPreferenceCategory = 'viewPreferenceCategory',
	ViewPreferenceDefinition = 'viewPreferenceDefinition',
	ViewQuestion = 'viewQuestion',
	ViewQuestionCategory = 'viewQuestionCategory',
	ViewRecent = 'viewRecent',
	ViewReport = 'viewReport',
	ViewReportSchedule = 'viewReportSchedule',
	ViewRole = 'viewRole',
	ViewTag = 'viewTag',
	ViewUnitType = 'viewUnitType',
	ViewUser = 'viewUser',
	ViewZoneNotification = 'viewZoneNotification',
	ViewZoneSettings = 'viewZoneSettings',
	viewSharedEntity = 'viewSharedEntity',
}
