import React, { Dispatch, SetStateAction, useState } from 'react';
import { AlertVariant, FormGroup, TreeViewDataItem } from '@patternfly/react-core';
import './KeyMeasureView.scss';
import { useMount } from 'react-use';
import { KeyMeasure, TKeyMeasure } from '../../../../api/analytics/KeyMeasure';
import { Dimension, TDimension } from '../../../../api/analytics/Dimension';
import { TKeyMeasureFact } from '../../../../api/analytics/KeyMeasureFact';
import Loader from '../../../util/Loader';
import { TDimensionAttribute } from '../../../../api/analytics/DimensionAttribute';
import { BuildTreeViewItem } from '../../../../helpers/tree-view.helper';
import SearchableTreeViewSelect from '../../../form/Select/SearchableTreeViewSelect';
import { defaultDateRangeDim } from '../../../../constants/default-date-range-dim.constant';

export type KeyMeasureViewProps = {
	onSelectMeasure: Dispatch<SetStateAction<TKeyMeasure | undefined>>;
	selectedMeasure?: TKeyMeasure;
	onSelectFact: Dispatch<SetStateAction<TKeyMeasureFact | undefined>>;
	selectedFact?: TKeyMeasureFact;
	onSelectDimension: Dispatch<SetStateAction<TDimension | undefined>>;
	selectedDimension?: TDimension;
	onSelectDimensionAttribute: Dispatch<SetStateAction<TDimensionAttribute | undefined>>;
	selectedDimensionAttribute?: TDimensionAttribute;
	setCreateDisabled: Dispatch<SetStateAction<boolean>>;
};

function KeyMeasureView(props: KeyMeasureViewProps) {
	const [keyMeasures, setKeyMeasures] = useState<TKeyMeasure[]>([]);

	const [series, setSeries] = useState<TDimension[]>([]);
	const [allSeries, setAllSeries] = useState<TDimension[]>([]);

	const [isLoading, setIsLoading] = useState(true);

	useMount(() => {
		props.onSelectDimensionAttribute(undefined);
		props.onSelectMeasure(undefined);
		props.onSelectFact(undefined);
		props.onSelectDimension(undefined);

		Promise.all([
			KeyMeasure.GetAll(['keyMeasureFacts', 'dimensions']),
			Dimension.GetAll(['dimensionAttributes']),
		])
			.then(([measures, dimensions]) => {
				measures.map((measure: TKeyMeasure) => {
					measure;
				});

				setKeyMeasures(measures);
				setAllSeries(dimensions);
			})
			.catch((e) => {
				addToast(
					'There was an issue retrieving measure and dimension data',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsLoading(false);
			});
	});

	const onFocusKeyMeasures = () => {
		const element = document.getElementById('km-toggle');
		element?.focus();
	};

	const onFocusSeries = () => {
		const element = document.getElementById('series-toggle');
		element?.focus();
	};

	const onSelectKeyMeasure = (
		event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	) => {
		props.onSelectDimensionAttribute(undefined);
		props.onSelectDimension(undefined);

		if (item.id && item.id?.includes('__') && item.id !== '-1') {
			const [measureId, factId] = item.id.split('__');

			const measure = keyMeasures.find((_) => _.id === +measureId);
			props.onSelectMeasure(measure);
			props.onSelectFact(measure?.keyMeasureFacts.find((_) => _.id === +factId));
			onFocusKeyMeasures();

			const dims = measure?.dimensions ?? [];
			const dimIds = (dims as TDimension[]).map((_) => _.id);

			const dropdownSeries = allSeries.filter((_) => dimIds.includes(_.id));

			dropdownSeries.map((serie: TDimension) => {
				const newDimAttribute: TDimensionAttribute = {
					code: serie.code,
					creator: serie.creator,
					created_at: serie.created_at,
					name: serie.name,
					id: serie.id,
					show_on_summary: serie.show_on_summary,
					type: 'DimensionAttribute',
					dimension: serie,
					disabled: false,
					display_attribute: true,
					effective_dated: false,
					sequence: 0,
				};
				const existingSeries = series.find((x) => x.id === serie.id);
				const existingDimAttr = existingSeries?.dimensionAttributes.find(
					(x) => x.id == serie.id
				);

				if (!existingDimAttr) serie.dimensionAttributes.push(newDimAttribute);
			});

			setSeries([...[defaultDateRangeDim], ...dropdownSeries]);
		} else if (item.id !== undefined && item.id !== '-1') {
			const id = parseInt(item.id) ?? 0;
			const measure = keyMeasures.find((_) => _.id === id);
			props.onSelectMeasure(measure);
			props.onSelectFact(measure?.keyMeasureFacts[0]);
			onFocusKeyMeasures();

			const dims = measure?.dimensions ?? [];
			const dimIds = (dims as TDimension[]).map((_) => _.id);

			const dropdownSeries = allSeries.filter((_) => dimIds.includes(_.id));

			dropdownSeries.map((serie) => {
				const newDimAttribute: TDimensionAttribute = {
					code: serie.code,
					creator: serie.creator,
					created_at: serie.created_at,
					name: serie.name,
					id: serie.id,
					show_on_summary: serie.show_on_summary,
					type: 'DimensionAttribute',
					dimension: serie,
					disabled: false,
					display_attribute: true,
					effective_dated: false,
					sequence: 0,
				};

				const existingSeries = series.find((x) => x.id === serie.id);
				const existingDimAttr = existingSeries?.dimensionAttributes.find(
					(x) => x.id == serie.id
				);

				if (!existingDimAttr) serie.dimensionAttributes.push(newDimAttribute);
			});
			setSeries([...[defaultDateRangeDim], ...dropdownSeries]);
		} else if (item.id && item.id === '-1') {
			props.onSelectFact(undefined);
		}
	};

	const onSelectDimension = (
		event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	) => {
		if (item.id === '-1') return;

		if (item.id?.includes('__')) {
			const [dimensionId, attributeId] = item.id.split('__');

			const dimension = series.find((_) => _.id.toString() === dimensionId);
			if (dimension) {
				const attr = dimension.dimensionAttributes.find(
					(_) => _.id.toString() === attributeId
				);
				props.onSelectDimensionAttribute(attr);
			}
		} else {
			const dimensionId = item.id ?? '0';
			const dimension = series.find((_) => _.id.toString() === dimensionId);
			props.onSelectDimension(dimension);
			const lastDimAttr =
				dimension?.dimensionAttributes[dimension?.dimensionAttributes.length - 1];
			props.onSelectDimensionAttribute(lastDimAttr);
		}

		onFocusSeries();
		props.setCreateDisabled(false);
	};

	const emptyTree = [
		BuildTreeViewItem({ id: -1, name: 'No Dimensions or Attributes were found' }, []),
	];

	return isLoading ? (
		<Loader />
	) : (
		<div
			className="options-container"
			data-testid="key-measure-view"
		>
			<div className="form-group-col">
				<FormGroup
					label="Key Measure"
					isRequired
				>
					<SearchableTreeViewSelect
						data-testid="key-measure-tree"
						className="key-measure-tree no-innerscroll"
						data={
							keyMeasures.length > 0
								? keyMeasures.map((km) => BuildTreeViewItem(km, km.keyMeasureFacts))
								: emptyTree
						}
						placeHolderText={props.selectedFact?.name ?? 'Select a Fact'}
						onSelect={onSelectKeyMeasure}
						treeItemsExpanded={true}
					/>
				</FormGroup>
			</div>
			{props.selectedMeasure && (
				<>
					<span>By</span>
					<FormGroup label="Series">
						<div className="form-group-col">
							<SearchableTreeViewSelect
								className="selectSeries no-innerscroll"
								data={
									series.length > 0
										? series.map((s) =>
												BuildTreeViewItem(s, s.dimensionAttributes)
										  )
										: emptyTree
								}
								placeHolderText={
									props.selectedDimensionAttribute?.name ??
									props.selectedDimension?.name ??
									'Select a Series or Series Attribute'
								}
								onSelect={onSelectDimension}
								treeItemsExpanded={true}
							/>
						</div>
					</FormGroup>
				</>
			)}
		</div>
	);
}

export default KeyMeasureView;
function addToast(arg0: string, danger: any) {
	throw new Error('Function not implemented.');
}
