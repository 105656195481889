export enum CardTypesEnum {
	Analyze = 'analyze',
	DataFrame = 'dataframes',
	Chart = 'charts',
	Dashboard = 'dashboards',
	Report = 'reports',
	Presentation = 'presentations',
	Favorite = 'favorites',
	Folder = 'folder',
	Folders = 'folders',
}
