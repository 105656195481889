import { Dropdown, DropdownItem, KebabToggle } from '@patternfly/react-core';
import { useState } from 'react';
import './AlertTile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowDownRight,
	faArrowRight,
	faArrowUpRight,
	faCircleDashed,
	faEllipsisVertical,
	faHistory,
	faLineChart,
	faPencilSquare,
	faSend,
} from '@fortawesome/pro-solid-svg-icons';
import { AlertTrendEnum } from '../../enums/alert-trend.enum';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import AlertTakeActionModal from '../../helpers/helper-components/AlertTakeActionModal';

export type AlertTileProps = {
	trend: AlertTrendEnum;
	alertValue: number;
	alertName: string;
	yoyPercentage?: number;
	handle: string;
};

function AlertTile(props: AlertTileProps) {
	const iconMap: Map<string, IconDefinition> = new Map<string, IconDefinition>([
		['down-trend', faArrowDownRight],
		['up-trend', faArrowUpRight],
		['no-change', faArrowRight],
		['just-info', faCircleDashed],
	]);

	const [isOpen, setIsOpen] = useState(false);
	const [isTakeActionClicked, setIsTakeActionClicked] = useState(false);

	const onToggle = () => {
		setIsOpen(!isOpen);
	};

	const setIsActionTaken = () => {
		setIsTakeActionClicked(true);
	};

	const closeModal = () => {
		setIsTakeActionClicked(false);
	};

	const dropdownItems = [
		<DropdownItem
			key="item-t-action-1"
			className="alert-action"
			onClick={setIsActionTaken}
		>
			Take Action <FontAwesomeIcon icon={faSend} />
		</DropdownItem>,
		<DropdownItem
			key="item-edit-1"
			className="alert-action"
		>
			Edit Alert <FontAwesomeIcon icon={faPencilSquare} />
		</DropdownItem>,
		<DropdownItem
			key="item-v-history-1"
			className="alert-action"
		>
			View History <FontAwesomeIcon icon={faHistory} />
		</DropdownItem>,
		<DropdownItem
			key="item-v-chart-1"
			className="alert-action"
		>
			View Chart <FontAwesomeIcon icon={faLineChart} />
		</DropdownItem>,
	];

	const showYoy = props.yoyPercentage !== undefined && props.yoyPercentage !== null;

	let trendKey: 'Even' | 'Down' | 'Up' = 'Even';
	if (props.yoyPercentage !== void 0) {
		if (props.yoyPercentage > 0) {
			trendKey = 'Up';
		}

		if (props.yoyPercentage < 0) {
			trendKey = 'Down';
		}
	}

	return (
		<div
			className={'tile bordered ' + props.trend}
			data-testid="alert-tile"
		>
			<div className="tile-content">
				<div className="tile-column">
					<FontAwesomeIcon
						data-testid="trend-icon"
						icon={iconMap.get(props.trend) ?? faCircleDashed}
					/>
				</div>
				<div className="tile-column">
					<div className="column-content">
						<span
							className="tile-header"
							data-testid="alert-value"
						>
							{props.alertValue}
						</span>
						<span data-testid="alertName">{props.alertName}</span>
					</div>
				</div>

				{showYoy ? (
					<div
						className="tile-column tile-content-right"
						data-testid="yoy-container"
					>
						<div className="column-content">
							<span
								className="tile-header"
								data-testid="yoy-value"
							>
								{props.yoyPercentage}%
							</span>
							<span className="sub-text">
								{trendKey} <br /> YOY
							</span>
						</div>
					</div>
				) : null}
			</div>
			<div className="tile-footer">
				<div className="spacer" />
				<div className="footer-content">
					<span data-testid="handle">{props.handle}</span>
					<Dropdown
						toggle={<KebabToggle onToggle={onToggle} />}
						isOpen={isOpen}
						isPlain
						dropdownItems={dropdownItems}
						className="alert-dropdown"
						direction="up"
					>
						<FontAwesomeIcon icon={faEllipsisVertical} />
					</Dropdown>
				</div>
			</div>

			<AlertTakeActionModal
				defaultAction="Take Action"
				isOpen={isTakeActionClicked}
				onClose={closeModal}
			/>
		</div>
	);
}

export default AlertTile;
