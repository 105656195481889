import React, { Dispatch, useEffect, useState } from 'react';
import ZiChart, { ZiChartProps } from './ZiChart';
import { Chart, TChart } from '../../api/charts/Chart';
// import { TChart } from '../../api/types/chart/chart';
import { AlertVariant } from '@patternfly/react-core';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import Loader from '../util/Loader';
import { Dataframe, TNewDataframeFilter } from '../../api/dataframes/Dataframes';
import {
	DataframeDataRetrievalRequest,
	DataframeDataRetrievalResponse,
	LambdaInsightsRequest,
	LambdaInsightsResponse,
	TDataframe,
	TDateRange,
} from '../../api/types';
import { formatCsvDataToColumns, MultipartResponse } from '../../helpers/multipart-response.helper';
import { Link, useParams } from 'react-router-dom';
import { useApplication, useUser } from '../user/ApplicationProvider';
import { ChartSeriesChartTypes } from '../../types/charts/chart-options';
import { UnitType, TUnitType } from '../../api/analytics/UnitType';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { DateRange } from '../../api/date-period-selector/DateRange';
import { DashboardFilter } from '../../api/dashbboards/DashboardFilter';
import { ZiChartSettings } from '../../types/charts/chart-settings';
import { getPreviewDataAndCalculateGrandTotal } from '../../helpers/chart.helper';
import { populateDroppedFactsByDataframe } from '../../hooks/DataBuilderHooks';
import { AxiosError } from 'axios';
import { useMount } from 'react-use';
import { DataframeDataJoin } from '../../api/dataframe-data-join/DataframesDataJoin';
import { PeriodTypesEnum } from '../../enums/period-types-enum';
import { timestampToMMDDYYYY } from '../../utilities';
import { DataBuilderTypes } from '../../enums/data-builder-types';
import { Lambda } from '../../api/lambda/Lambda';
import { OptionsBuilderItemTypes } from '../../types/dataframes/options-builder-item-types';
import { Dimension, TDimension } from '../../api/analytics/Dimension';

type Props = {
	chartId: string;
	selectedDate?: TNewDateRange;
	allowClickNavigate?: boolean;
	height?: number | string;
	width?: number | string;
	filters?: DashboardFilter[];
	isEdit?: boolean;
	hideXAxis?: boolean;
	hideTitle?: boolean;
	transparentBackground: boolean;
	delayedDisplayTime?: number;
	hideYAxis?: boolean;
	showInsights?: boolean;
	setLambdaInsights?: Dispatch<React.SetStateAction<LambdaInsightsResponse | undefined>>;
};

const ChartView = (props: Props) => {
	const {
		chartId,
		selectedDate,
		allowClickNavigate,
		height,
		width,
		filters,
		isEdit,
		hideXAxis,
		hideTitle,
		transparentBackground,
		delayedDisplayTime,
		hideYAxis,
	} = props;
	const { addToast } = useToast();
	const { dashboardId, presentationId } = useParams();
	const { currentDatePeriods } = useApplication();
	const defaultDatePeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [chart, setChart] = useState<TChart | null>(null);

	const [chartSettings, setChartSettings] = useState<ZiChartSettings>({
		chartColor: '',
		hideLegend: true,
		hideYAxis: hideYAxis ?? false,
		limitResults: 0,
		showAverage: false,
		showDataValues: true,
		showPercentages: false,
		showScrollbar: false,
		showTargets: false,
		showValues: false,
		hideXAxis,
		hideTitle,
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [chartRetrevialData, setChartRetrevialData] = useState<any[]>([]);
	const [chartRetrevialCSVData, setChartRetrevialCSVData] =
		useState<MultipartResponse<DataframeDataRetrievalResponse>>();
	const [chartProps, setChartProps] = useState<ZiChartProps>({
		isConfigMode: false,
		displayChart: true,
		title: '',
		categoryTitle: '',
		valueTitle: '',
		chartRetrevialData: chartRetrevialData ?? [],
		chartData: chart ?? undefined,
		transparentBackground: transparentBackground,
		...(height && width && { size: { height, width } }),
		containerProps: { style: { height: '100%' } },
		chartSettings,
		setChartSettings,
		showLimitWarning: false,
		grandTotal: [{}],
	});

	const [dataframe, setDataframe] = useState<TDataframe>();
	const [dataframeHasJoins, setDataframeHasJoins] = useState<boolean>();
	const [hiddenClass, setHiddenClass] = useState<string>('hidden');
	const [unitType, setUnitType] = useState<TUnitType>();
	const [unitTypes, setUnitTypes] = useState<TUnitType[]>();
	const previousSelectedStartPeriod = localStorage.getItem('currentSelectedStartPeriod');
	const previousSelectedEndPeriod = localStorage.getItem('currentSelectedEndPeriod');
	const hasPreviouslySelectedPeriod =
		previousSelectedStartPeriod != null || previousSelectedEndPeriod != null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedStartDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedStartPeriod
			? JSON.parse(previousSelectedStartPeriod)
			: null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedEndDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedEndPeriod
			? JSON.parse(previousSelectedEndPeriod)
			: null;
	const currentUser = useUser();
	const [dimensions, setDimensions] = useState<TDimension[]>([]);

	const getChartLink = () => {
		let url = `/analyze/charts/build/chart/${chartId}`;
		if (dashboardId) {
			url = `/analyze/dashboards/${dashboardId}/chart/${chartId}`;
		} else if (presentationId) {
			url = `/present/${presentationId}/chart/${chartId}`;
		}

		return url;
	};

	useMount(() => {
		if (props.showInsights) {
			getDimensions();
		}
		getUnitTypes();
		getChart();
	});

	useEffect(() => {
		if (chart && dataframe && chartRetrevialData) {
			setIsLoading(true);
			getRetrievalData();
		}
	}, [
		selectedDate,
		selectedDate?.begin_date,
		selectedDate?.end_date,
		selectedDate?.period,
		filters,
	]);

	useEffect(() => {
		if (chart) {
			getChartDataframe();
		}
	}, [chart]);

	useEffect(() => {
		if (dataframe) {
			getDataframeJoins();
		}
	}, [dataframe]);

	useEffect(() => {
		getDataframeUnitType();
	}, [dataframeHasJoins]);

	useEffect(() => {
		getRetrievalData();
	}, [unitType]);

	useEffect(() => {
		if (dataframe && chartRetrevialData && chart) {
			const previewDataAndGrandTotal = getPreviewDataAndCalculateGrandTotal(
				populateDroppedFactsByDataframe(dataframe, []),
				chartRetrevialData,
				true
			);

			setChartProps({
				...chartProps,
				isSingleSeries: dataframe.rowEntry.length == 1,
				chartRetrevialData: chartRetrevialData,
				chartType: chart.chart_type as ChartSeriesChartTypes,
				chartData: chart,
				title: chart.name,
				selectedNumDecimals: chart.num_decimals,
				...(unitType && { selectedUnitType: unitType }),
				...(unitType && { unitType: unitType }),
				...(unitType && { valueTitle: unitType.name }),
				chartSettings: chartSettings,
				showLimitWarning: false,
				grandTotal: previewDataAndGrandTotal?.grandTotal,
				droppedFacts: populateDroppedFactsByDataframe(dataframe, []),
				unitTypes,
			});

			// Time out used to prevent the flash of the chart component before all data has initialized
			if (delayedDisplayTime) {
				setTimeout(() => {
					setHiddenClass('');
				}, delayedDisplayTime);
			} else {
				setHiddenClass('');
			}

			setIsLoading(false);
		}
	}, [chartRetrevialData]);

	useEffect(() => {
		if (props.showInsights) {
			getDimensions();
		}
	}, [props.showInsights]);

	useEffect(() => {
		if (dimensions.length && props.setLambdaInsights) {
			getInsightsData();
		}
	}, [dimensions]);

	const getDataframeJoins = () => {
		if (dataframe?.parent) {
			void DataframeDataJoin.Get(dataframe.parent).then((response) => {
				setDataframeHasJoins(response.length ? true : false);
			});
		} else {
			setDataframeHasJoins(false);
		}
	};

	const getDataframeUnitType = () => {
		if (dataframe?.datasets[0] && dataframe.datasets[0].unitType) {
			UnitType.Get(dataframe.datasets[0].unitType)
				.then((response: TUnitType): void => {
					setUnitType(response);
				})
				.catch((): void => {
					addToast('Error fetching selected unit type.', AlertVariant.danger);
				});
		} else {
			setUnitType(undefined);
		}
	};

	const getRetrievalData = () => {
		if (dataframe) {
			const previousDate = {
				begin_date:
					hasPreviouslySelectedPeriod && previouslySelectedStartDate
						? previouslySelectedStartDate?.begin_date
						: defaultDatePeriod?.begin_date ?? 0,
				end_date:
					hasPreviouslySelectedPeriod && previouslySelectedEndDate
						? previouslySelectedEndDate.end_date
						: defaultDatePeriod?.end_date ?? 0,
				period:
					hasPreviouslySelectedPeriod && previouslySelectedStartDate
						? previouslySelectedStartDate.period
						: defaultDatePeriod?.period ?? 0,
			};

			const dateToUse = selectedDate ?? previousDate;

			const request: DataframeDataRetrievalRequest = {
				dataframeId: dataframe.id,
				begin_date: dateToUse.begin_date,
				end_date: dateToUse.end_date,
				periodId: dateToUse.period,
				override: {
					id: dataframe.id,
					...(dataframe.parent && { parent: dataframe.parent }),
					columnEntry: [],
					datasets: dataframe.datasets,
					name: 'override',
					rowEntry: dataframe.rowEntry,
					order: dataframe.order,
					filters: mapFilters(filters ?? [], dataframe),
				},
			};

			Dataframe.Retrieve(request)
				.then((newDataResponse) => {
					const newResponseRetrevialData = formatCsvDataToColumns(
						newDataResponse.json?.columns ?? [],
						newDataResponse.csvData ?? [],
						dateToUse?.period ?? 3
					);
					setChartRetrevialData(newResponseRetrevialData);
					setChartRetrevialCSVData(newDataResponse);
				})
				.catch((e: AxiosError<{ message: string }>): void => {
					if (e.response?.data?.message) {
						addToast(e.response.data.message, AlertVariant.danger);
					} else {
						addToast('Error fetching data.', AlertVariant.danger);
					}
					setIsLoading(false);
				});
		}
	};

	const getChartDataframe = () => {
		Dataframe.Get(chart?.dataframe ?? 0, ['datasets', 'filters', 'rowEntry', 'order'])
			.then((dataframeResponse) => {
				setDataframe(dataframeResponse);
			})
			.catch(() => {
				setIsLoading(false);
				addToast('There was an issue retrieving the dataframe', AlertVariant.danger);
			});
	};

	const getChart = () => {
		setIsLoading(true);
		Chart.GetOne(parseInt(chartId))
			.then((chartResponse: TChart): void => {
				setChart(chartResponse);
				setChartSettings({
					chartColor: chartResponse.color_set,
					hideLegend: chartResponse.hide_legend,
					hideYAxis: hideYAxis ?? chartResponse.hide_y_axis,
					limitResults: 500,
					showAverage: chartResponse.show_average,
					showDataValues: chartResponse.show_data_values,
					showPercentages: chartResponse.show_percentages,
					showScrollbar: chartResponse.show_scrollbar,
					showTargets: chartResponse.show_targets,
					showValues: chartResponse.show_targets,
					hideXAxis,
					hideTitle,
				});
			})
			.catch((): void => {
				addToast('Error fetching dataframe chart data.', AlertVariant.danger);
				setIsLoading(false);
			})
			.catch((): void => {
				addToast('Error fetching chart data.', AlertVariant.danger);
				setIsLoading(false);
			});
	};

	const getUnitTypes = () => {
		UnitType.GetAll()
			.then((response: TUnitType[]): void => {
				setUnitTypes(response);
			})
			.catch((): void => {
				addToast('Error fetching unit types.', AlertVariant.danger);
			});
	};

	const getDimensions = () => {
		void Dimension.GetAll(['dimensionAttributes']).then((response) => {
			setDimensions(response);
		});
	};

	const getInsightsData = () => {
		if (dataframe && chartRetrevialCSVData) {
			const previousDate = {
				begin_date:
					hasPreviouslySelectedPeriod && previouslySelectedStartDate
						? previouslySelectedStartDate?.begin_date
						: defaultDatePeriod?.begin_date ?? 0,
				end_date:
					hasPreviouslySelectedPeriod && previouslySelectedEndDate
						? previouslySelectedEndDate.end_date
						: defaultDatePeriod?.end_date ?? 0,
				period:
					hasPreviouslySelectedPeriod && previouslySelectedStartDate
						? previouslySelectedStartDate.period
						: defaultDatePeriod?.period ?? 0,
			};

			const date = selectedDate ?? previousDate;

			const request: LambdaInsightsRequest = {
				metadata: {
					key_measures: dataframe?.datasets.map((fact) => {
						return {
							name: fact.title ?? '',
							description: '',
						};
					}),
					dimensions: dataframe?.rowEntry.map((dim) => {
						return {
							name: dim.title ?? '',
							description: '',
						};
					}),
					columns: chartRetrevialCSVData.json?.columns ?? [],
					...getPeriodData(date),
					filters_applied: getInsightsFilters(),
				},
				data: chartRetrevialCSVData.csvDataUnformatted ?? '',
				logging: {
					user_id: currentUser.id,
					entity_type: DataBuilderTypes.chart,
					entity_id: chart?.id ?? 0,
				},
			};

			Lambda.GetInsights(request)
				.then((response) => {
					if (props.setLambdaInsights) {
						if (response.success) {
							props.setLambdaInsights(response.data);
						} else {
							addToast(response.message, AlertVariant.danger);
							props.setLambdaInsights(undefined);
						}
					}
				})
				.catch((): void => {
					addToast('Error fetching insights.', AlertVariant.danger);
					props.setLambdaInsights && props.setLambdaInsights(undefined);
				});
		}
	};

	const getInsightsFilters = () => {
		const filters: string[] = [];
		if (dataframe) {
			dataframe.filters.forEach((filter) => {
				if (filter.entity_type === OptionsBuilderItemTypes.DimensionAttribute) {
					const dim = dimensions.find((dim) =>
						dim.dimensionAttributes.some((dimAttr) => dimAttr.id === filter.entity_id)
					);
					if (dim) {
						const dimAttr = dim.dimensionAttributes.find(
							(dimAttr) => dimAttr.id === filter.entity_id
						);

						if (dimAttr) {
							filters.push(`${dimAttr.name ?? ''}: ${filter.value ?? ''}`);
						}
					}
				} else if (filter.entity_type === OptionsBuilderItemTypes.Dimension) {
					const dim = dimensions.find((dim) => dim.id === filter.entity_id);

					if (dim) {
						filters.push(`${dim.name ?? ''}: ${filter.value ?? ''}`);
					}
				}
			});
		}

		return filters;
	};

	const getPeriodData = (date: { begin_date: number; end_date: number; period: number }) => {
		const response = {
			time_period: '',
			time_range: {
				start_date: '',
				end_date: '',
			},
		};

		if (date) {
			response.time_range.start_date = timestampToMMDDYYYY(date.begin_date);
			response.time_range.end_date = timestampToMMDDYYYY(date.end_date);

			switch (date.period) {
				case 1: {
					response.time_period = PeriodTypesEnum.Year;
					break;
				}
				case 2: {
					response.time_period = PeriodTypesEnum.Quarter;
					break;
				}
				case 3: {
					response.time_period = PeriodTypesEnum.Month;
					break;
				}
				case 4: {
					response.time_period = PeriodTypesEnum.Week;
					break;
				}
				default: {
					response.time_period = PeriodTypesEnum.Month;
					break;
				}
			}
		}

		return response;
	};

	const mapFilters = (
		filters: DashboardFilter[],
		dataframe: TDataframe
	): TNewDataframeFilter[] => {
		const filtersToReturn: TNewDataframeFilter[] = [];

		filters.forEach((filter) => {
			filtersToReturn.push({
				entity_id: filter.entity_id,
				entity_type: filter.entity_type,
				excluded: filter.excluded,
				isExistingValue: filter.isExistingValue,
				operator: filter.operator,
				value: filter.value,
			});
		});

		return [...filtersToReturn, ...dataframe.filters];
	};

	const getSize = () => {
		if (height || width) {
			return {
				height: height ? height : '',
				width: width ? width : '',
			};
		}
	};

	const componentStyles = {
		height: '100%',
		width: '100%',
	};

	let component = <Loader />;

	if (!isLoading) {
		component = (
			<div
				style={componentStyles}
				className={hiddenClass}
			>
				<ZiChart
					{...chartProps}
					chartRetrevialData={chartRetrevialData || []}
					size={getSize()}
				/>
			</div>
		);

		if (allowClickNavigate && !isEdit) {
			component = (
				<div
					style={componentStyles}
					className={hiddenClass}
				>
					<Link to={getChartLink()}>{component}</Link>
				</div>
			);
		}
	}
	return component;
};

export default ChartView;
