import { GenericKeyValueResponse } from '../api/types';

export function containsTwoInstances(str: string, term: string): boolean {
	// Construct a regular expression to match the term
	const regex = new RegExp(term, 'g');

	// Use match method to find all occurrences of the term
	const matches = str.match(regex);

	// Check if matches exist and if there are at least two instances
	return matches !== null && matches.length >= 2;
}

export function parseFolderSegments(pathname: string): GenericKeyValueResponse<string | number>[] {
	const segments = pathname.split('/folder/');
	const result: GenericKeyValueResponse<string | number>[] = [];

	// Start from index 1 to skip the first part of the pathname
	for (let i = 1; i < segments.length; i++) {
		const folderSegments = segments[i].split('/');
		if (folderSegments.length >= 2) {
			result.push({
				id: parseInt(folderSegments[1]),
				value: folderSegments[0],
			});
		}
	}

	return result;
}
