import { CardTypesEnum } from '../../enums/card-types.enum';
import { ChartsAndDataframes } from './chartsAndDataframes-context';
import { Dashboards } from './dashboard-context';
import { Presentations } from './presentation-context';
import { Reports } from './report-context';

export const getAll = () => {
	return [...ChartsAndDataframes, ...Dashboards, ...Reports, ...Presentations];
};

export const getAllRecent = () => {
	return getAll().filter((card) => card.isRecent);
};

export const getAllFavorites = () => {
	return getAll().filter((card) => card.isFavorite);
};

export const getDashboards = () => {
	return getAll().filter((card) => card.type === CardTypesEnum.Dashboard);
};

export const getCharts = () => {
	return getAll().filter((card) => card.type === CardTypesEnum.Chart);
};

export const getReports = () => {
	return getAll().filter((card) => card.type === CardTypesEnum.Report);
};

export const getPresentations = () => {
	return getAll().filter((card) => card.type === CardTypesEnum.Presentation);
};

export const getRecentDashboards = () => {
	return getAllRecent().filter((card) => card.type === CardTypesEnum.Dashboard);
};

export const getRecentCharts = () => {
	return getAllRecent().filter((card) => card.type === CardTypesEnum.Chart);
};

export const getRecentReports = () => {
	return getAllRecent().filter((card) => card.type === CardTypesEnum.Report);
};

export const getRecentPresentations = () => {
	return getAllRecent().filter((card) => card.type === CardTypesEnum.Presentation);
};

export const GetAllAnalyze = () => {
	return ChartsAndDataframes;
};

export const getDataframes = () => {
	return getAll().filter((card) => card.type === CardTypesEnum.DataFrame);
};

export const getRecentDataframes = () => {
	return getDataframes().filter((card) => card.isRecent);
};
