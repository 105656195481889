import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DashboardLayoutType } from './Dashboard';
import {
	AlertVariant,
	Button,
	Card,
	CardBody,
	Flex,
	FlexItem,
	FormGroup,
	Grid,
	GridItem,
	TextInput,
} from '@patternfly/react-core';
import { Dashboard } from '../../../api/dashbboards/Dashboards';
import { DashboardWidget } from '../../../api/dashbboards/DashboardWidgets';
import { useMount } from 'react-use';
import DropGrid from '../../../components/dnd/DropGrid';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import Loader from '../../../components/util/Loader';

import ZiChartOptionsModal, {
	ZiChartOptionsModalProps,
} from '../../../components/modals/charts/ZiChartOptionsModal';
import { GridBorderOutletContext, GridLayoutOutletContext } from '../../../layout/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TreeViewSelect from '../../../components/form/Select/TreeViewSelect';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { Folder, TFolder } from '../../../api/foundational-elements/Folder';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { addNewRecentDashboard } from '../../../helpers/helper-components/recents-factory-helper';
import { DashboardFilter } from '../../../api/dashbboards/DashboardFilter';
import DashboardFilters from './DashboardFilters';
import { TNewDateRange } from '../../../api/types/TNewDateRange';
import PageTitleSubheader from '../../../layout/subheader/PageTitleSubheader';
import WidgetLibrary from '../../../components/dnd/widgets/WidgetLibrary';

type Props = {
	type: DashboardLayoutType;
};
const DashboardDetails = (_props: Props) => {
	const { gridLayout } = useOutletContext<GridLayoutOutletContext>();
	const { gridBorder } = useOutletContext<GridBorderOutletContext>();
	const { dashboardId } = useParams();
	const navigate = useNavigate();
	const { addToast } = useToast();
	const [folders, setFolders] = useState<TFolder[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [dashboardModel, setDashboardModel] = useState<Dashboard>({
		...Dashboard.Default(),
	});
	const [widgets, setWidgets] = useState<DashboardWidget[]>([]);
	const [dashboardFilters, setDasboardFilters] = useState<DashboardFilter[]>();
	const [selectedDate, setSelectedDate] = useState<TNewDateRange>();
	const { setSubSide, subNavExpanded, setSubNavExpanded, updateGridLayout, updateGridBorder } =
		useOutletContext<GridLayoutOutletContext>();

	const updateSelectedDate = useCallback(
		(startDateRange: TNewDateRange, endDateRange: TNewDateRange) => {
			if (setSelectedDate) {
				setSelectedDate({
					begin_date: startDateRange.begin_date,
					end_date: endDateRange.end_date,
					period: startDateRange.period,
					sequence: startDateRange.sequence,
				});
			}
		},
		[selectedDate, setSelectedDate]
	);

	useMount(() => {
		Folder.GetAll({ type: 'dashboards' })
			.then((response) => {
				if (response) {
					setFolders(response.filter((f) => f.type === 'folders'));
				} else {
					setFolders([
						{
							id: -1,
							name: 'No folders found',
							type: 'dashboard',
							items: [],
						},
					]);
				}
			})
			.catch(() => {
				addToast('Get folders failed.', AlertVariant.danger);
			});
		if (dashboardId) {
			setLoading(true);
			Dashboard.Get(parseInt(dashboardId))
				.then((dashboardResponse) => {
					if (dashboardResponse) {
						setDashboardModel({ ...dashboardResponse });
						DashboardWidget.GetAll({ dashboard: dashboardId })
							.then((widgetsResponse) => {
								setWidgets(widgetsResponse);
								setLoading(false);
							})
							.catch(() => {
								addToast('Get all widgets failed.', AlertVariant.danger);
							});
					}
				})
				.catch(() => {
					addToast('Get dashboard failed.', AlertVariant.danger);
				});

			//log entry into recents for loaded dashboard
			addNewRecentDashboard(dashboardId);
		}
	});

	useEffect(() => {
		if (dashboardId && dashboardModel.hasBorders === false) {
			createSubSide(dashboardModel.hasBorders);
		} else if (!dashboardId) {
			createSubSide();
		}
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const createSubSide = (hasBorders?: boolean) => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle={''}
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
				/>
			),
			hideLeftSideBar: true,
			rightSideBar: (
				<WidgetLibrary
					isDashboard
					gridLayout
					gridBorder={hasBorders}
					updateGridLayout={updateGridLayout}
					updateGridBorder={updateGridBorder}
				/>
			),
		});
	};

	const handleNameChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
		setDashboardModel({ ...dashboardModel, name: value });
	};

	const removeWidget = useCallback((widget: DashboardWidget) => {
		if (widget.id) {
			DashboardWidget.Delete(widget.id)
				.then(() => {
					setWidgets((widgets) => [...widgets.filter((w) => w.id != widget.id)]);
				})
				.catch(() => {
					addToast('Widget remove failed.', AlertVariant.danger);
				});
		} else {
			setWidgets((widgets) => [...widgets.filter((w) => w.id != widget.id)]);
		}
	}, []);

	const handleDashboardSave = () => {
		dashboardModel.hasBorders = gridBorder ?? false;

		if (!dashboardModel.id) {
			Dashboard.New(dashboardModel)
				.then((dashboardResponse) => {
					addToast('Dashboard created successfully.', AlertVariant.success);
					setDashboardModel({ ...dashboardModel, ...dashboardResponse });
				})
				.catch(() => {
					addToast('Dashoard create failed.', AlertVariant.danger);
				});
		} else {
			Dashboard.Edit(dashboardModel)
				.then((dashboardResponse) => {
					addToast('Dashboard updated successfully.', AlertVariant.success);
					setDashboardModel({ ...dashboardModel, ...dashboardResponse });
				})
				.catch(() => {
					addToast('Dashboard update failed.', AlertVariant.danger);
				});
		}
	};

	const handleDashboardWidgetSave = useCallback(
		(widget: DashboardWidget) => {
			widget.dashboard = dashboardModel.id ?? 0;
			if (!widget.id) {
				DashboardWidget.New(widget)
					.then((widgetResponse) => {
						setWidgets((widgets) => {
							return [...widgets, widgetResponse];
						});
					})
					.catch(() => {
						addToast('Widget add failed.', AlertVariant.danger);
					});
			} else {
				DashboardWidget.Edit(widget)
					.then((widgetResponse) => {
						setWidgets((widgets) => {
							const newWidgets = [...widgets];
							newWidgets[widgets.findIndex((w) => w.id === widgetResponse.id)] = {
								...widgetResponse,
							};
							return newWidgets;
						});
					})
					.catch(() => {
						addToast('Widget update failed.', AlertVariant.danger);
					});
			}
		},
		[dashboardModel]
	);

	const handleChartEditClick = useCallback(
		(widget: DashboardWidget) => {
			if (dashboardModel.id && widget.chart) {
				navigate(`/analyze/dashboards/${dashboardModel.id}/chart/${widget.chart}`);
			}
		},
		[dashboardModel]
	);

	const handleDashboardModalToggle = () => {
		setIsModalOpen((prev) => !prev);
	};

	const optionsModalProps: ZiChartOptionsModalProps = {
		isOpen: isModalOpen,
		onClose: handleDashboardModalToggle,
		dashboardId: dashboardModel.id,
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Card
				className={'dashboard-details-container'}
				style={{ marginBottom: '0.24rem' }}
			>
				<CardBody style={{ paddingBottom: '1.5rem' }}>
					<Grid
						className="row-container"
						span={12}
						style={{ paddingBottom: '0rem' }}
					>
						<div className="start-container">
							<FormGroup
								label={'Dashboard Name'}
								type="text"
								isRequired
								fieldId="name"
								helperTextInvalid={'Dashboard Name is required'}
								helperTextInvalidIcon={<ExclamationCircleIcon />}
							>
								<TextInput
									isRequired
									type="text"
									aria-label={'Enter a Dashboard name'}
									placeholder={'Enter a Dashboard name'}
									value={dashboardModel.name}
									onChange={handleNameChange}
								/>
							</FormGroup>
							<FormGroup
								label={<FontAwesomeIcon icon={faFolder} />}
								type="text"
								fieldId="folder"
								className="folder-container"
							>
								<TreeViewSelect
									onSelect={(e, item) => {
										if (item && item.id && item.id != '-1') {
											setDashboardModel({
												...dashboardModel,
												folder: parseInt(item.id),
											});
										}
									}}
									data={
										folders
											? folders.map((_: TFolder) =>
													BuildTreeViewItem(_, _.items)
											  )
											: []
									}
									placeHolderText={
										dashboardModel.folder
											? folders.find((f) => f.id === dashboardModel.folder)
													?.name
											: 'Dashboard'
									}
								/>
							</FormGroup>
						</div>

						<GridItem span={4}>
							<Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
								<FlexItem>
									<Button onClick={handleDashboardSave}>Save</Button>
								</FlexItem>
								<FlexItem>
									<Button
										variant="secondary"
										onClick={() =>
											navigate(
												`/analyze/dashboards/view/${dashboardModel.id ?? 0}`
											)
										}
									>
										Return
									</Button>
								</FlexItem>
							</Flex>
						</GridItem>
					</Grid>
					<DashboardFilters
						dashboardId={dashboardModel.id ?? 0}
						setFilters={setDasboardFilters}
						setSelectedDate={updateSelectedDate}
					/>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<Grid hasGutter>
						<GridItem span={12}>
							{!dashboardFilters ? (
								<Loader />
							) : (
								<DropGrid
									widgets={widgets}
									handleWidgetSave={handleDashboardWidgetSave}
									removeWidget={removeWidget}
									handleChartEditClick={handleChartEditClick}
									isDashboard
									isEdit
									gridLayout={gridLayout}
									gridBorder={dashboardModel.hasBorders ?? false}
									filters={dashboardFilters}
									selectedDate={selectedDate}
								/>
							)}
						</GridItem>
					</Grid>
				</CardBody>
			</Card>
			<ZiChartOptionsModal {...optionsModalProps} />
		</>
	);
};

export default DashboardDetails;
