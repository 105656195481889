import { DatePeriodFormatter } from '../components/date-period-selector/DatePeriodFormatter';

enum CustomDataFormatTypes {
	date = 'DateSeries',
}

export enum MultipartResponseContentTypes {
	json = 'Content-Type: application/json',
	csv = 'Content-Type: text/csv',
}

export enum MultipartResponseBoundaryTypes {
	boundary = 'boundary',
}

export type MultipartResponse<T> = {
	json?: T;
	csvData?: string[];
	csvDataUnformatted?: string;
};

export const parseMultipartResponse = <T>(
	response: string,
	boundary: MultipartResponseBoundaryTypes,
	contentTypes: MultipartResponseContentTypes[]
): MultipartResponse<T> => {
	const data: MultipartResponse<T> = {};
	const boundarySplit = response.split(`--${boundary}`);

	boundarySplit.map((_: string) => {
		const type = getUsedContentType(_, contentTypes);
		let object = _;

		switch (type) {
			case MultipartResponseContentTypes.csv: {
				object = object
					.replace(MultipartResponseContentTypes.csv, '')
					.replace(/\r\n/g, '')
					.trim();

				if (object.length > 0) {
					data.csvData = object.split(/\n/);
					data.csvDataUnformatted = object;
				}
				break;
			}
			case MultipartResponseContentTypes.json: {
				object = object
					.replace(/\r\n|\n\r|\n|\r/g, '')
					.replace(MultipartResponseContentTypes.json, '');
				data.json = JSON.parse(object) as T;
				break;
			}
		}
	});

	return data;
};

export const formatCsvDataToColumns = <T>(
	columns: string[],
	data: string[],
	periodId?: number
): T[] => {
	let obj: T[] = [];

	data.map((_) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const objToPush: { [key: string]: any } = {};
		const properties = rebuildCsvStringArray(_);

		columns.forEach((col, i) => {
			if (properties[i]) {
				objToPush[formatCsvColumn(col)] = properties[i];
			} else {
				objToPush[formatCsvColumn(col)] = null;
			}
		});

		obj.push(objToPush as T);
	});

	if (periodId) {
		obj = handleCustomDataFormatting(obj, periodId);
	}

	return obj;
};

export const rebuildCsvStringArray = (data: string, removeQuotes = true): string[] => {
	let rebuiltString = '';
	let quoteFlag = false;

	for (let __ of data) {
		if (__ === '"' && !quoteFlag) {
			quoteFlag = true;
		} else if (__ === '"' && quoteFlag) {
			quoteFlag = false;
		}

		if (__ === ',' && !quoteFlag) {
			__ = '|';
		}

		if (removeQuotes) {
			if (__ !== '"') {
				rebuiltString += __;
			}
		} else {
			rebuiltString += __;
		}
	}

	return rebuiltString.split('|');
};

export const formatCsvColumn = (column: string): string => {
	return column.replace(/\s/g, '').replace(',', '_');
};

const getUsedContentType = (part: string, contentTypes: MultipartResponseContentTypes[]) => {
	return contentTypes.find((_) => {
		return part.includes(_);
	});
};

export const handleCustomDataFormatting = <T>(obj: T[], periodId: number): T[] => {
	obj.forEach((row) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
		Object.keys(row as any).forEach((col) => {
			switch (col) {
				case CustomDataFormatTypes.date:
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
					(row as any)[col as string] = DatePeriodFormatter(
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
						(row as any)[col as string],
						periodId,
						true
					);
					break;
			}
		});
	});

	return obj;
};

export const convertUnixToDate = (unixStamp: string) => {
	const date = new Date(parseInt(unixStamp) * 1000);
	const options: Intl.DateTimeFormatOptions = {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
	};

	return new Intl.DateTimeFormat('en-US', options).format(date);
};
