import axiosConfig from '../axiosConfig';

export type TDataframeDataJoin = {
	id?: number;
	key_measure_from: number;
	key_measure_to: number;
	dimension: number;
	dataframe_data_id: number;
	join_type: string;
};

export type TDataframeDataJoinRequest = {
	dataframe_id: number;
};

export class DataframeDataJoin implements TDataframeDataJoin {
	id?: number;
	key_measure_from: number;
	key_measure_to: number;
	dimension: number;
	dataframe_data_id: number;
	join_type: string;

	constructor(dataframeDataJoin: TDataframeDataJoin) {
		this.id = dataframeDataJoin.id;
		this.key_measure_from = dataframeDataJoin.key_measure_from;
		this.key_measure_to = dataframeDataJoin.key_measure_to;
		this.dimension = dataframeDataJoin.dimension;
		this.dataframe_data_id = dataframeDataJoin.dataframe_data_id;
		this.join_type = dataframeDataJoin.join_type;
	}

	public static Default(): TDataframeDataJoin {
		return {
			dataframe_data_id: 0,
			dimension: 0,
			join_type: '',
			key_measure_from: 0,
			key_measure_to: 0,
		};
	}

	public static New(dataframe: TDataframeDataJoin): Promise<TDataframeDataJoin> {
		return axiosConfig.instance.post('/app/dataframe-data-join/', dataframe).then((res) => {
			return new DataframeDataJoin(res.data as TDataframeDataJoin);
		});
	}

	public static Get(id: number): Promise<TDataframeDataJoin[]> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return (
			axiosConfig.instance
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				.get(`app/get-dataframe-data-join-by-dataframe/${id}`)
				.then((res) => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
					return res.data?.length > 0 ? res.data : [];
				})
		);
	}

	public static Delete(id: number): Promise<TDataframeDataJoin> {
		return axiosConfig.instance.delete(`/app/dataframe-data-join/${id}`).then((res) => {
			return new DataframeDataJoin(res.data as TDataframeDataJoin);
		});
	}
}
