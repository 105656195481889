import {
	faChartArea,
	faChartSimpleHorizontal,
	faFilter,
	faHeat,
	faChartLineUp,
	faChartPie,
	faDiagramSankey,
	faChartScatter,
	faChartScatterBubble,
	faTable,
	faCloudWord,
	faChartSimple,
	faLollipop,
	faChartPyramid,
	faDiagramNested,
	faGauge,
} from '@fortawesome/pro-light-svg-icons';
import { ChartTypesEnum } from '../../enums/chart-types.enum';

export const ChartIconMap = {
	[ChartTypesEnum.Area]: faChartArea,
	[ChartTypesEnum.Bar]: faChartSimpleHorizontal,
	[ChartTypesEnum.Chart]: faTable,
	[ChartTypesEnum.Funnel]: faFilter,
	[ChartTypesEnum.Heat]: faHeat,
	[ChartTypesEnum.Heatmap]: faHeat,
	[ChartTypesEnum.Line]: faChartLineUp,
	[ChartTypesEnum.Packed]: faChartScatterBubble,
	[ChartTypesEnum.Pie]: faChartPie,
	[ChartTypesEnum.Sankey]: faDiagramSankey,
	[ChartTypesEnum.Scatter]: faChartScatter,
	[ChartTypesEnum.WordCloud]: faCloudWord,
	[ChartTypesEnum.Column]: faChartSimple,
	[ChartTypesEnum.Lollipop]: faLollipop,
	[ChartTypesEnum.Pyramid]: faChartPyramid,
	[ChartTypesEnum.Solidgauge]: faGauge,
	[ChartTypesEnum.Stackedbar]: faChartSimpleHorizontal,
	[ChartTypesEnum.Stackedcolumn]: faChartSimple,
	[ChartTypesEnum.Clusteredbar]: faChartSimpleHorizontal,
	[ChartTypesEnum.Clusteredcolumn]: faChartSimple,
	[ChartTypesEnum.Dependencywheel]: faDiagramNested,
};
