/* eslint-disable */
import { TUnitType } from '../../api/analytics/UnitType';
import { DraggableMenuItemData } from '../../types/databuilder/databuilder';
import { IIndexable } from '../../types/general';

export const removeLastKey = (obj: IIndexable) => {
	const keys = Object.keys(obj);
	if (keys.length === 0) {
		return obj; // Return the object as is if it's empty
	}

	const lastKey = keys[keys.length - 1];
	delete obj[lastKey];

	return obj;
};

export const getValueFromNestedObject = (obj: any, path: string[]) => {
	return path.reduce((acc, key) => {
		if (acc && typeof acc === 'object' && key in acc) {
			return acc[key];
		} else {
			return undefined;
		}
	}, obj);
};

export const getValueByKey = (obj: any, key: string): any | undefined => {
	if (typeof obj !== 'object' || obj === null) {
		return undefined;
	}

	if (key in obj) {
		return obj[key];
	}

	for (const k in obj) {
		if (obj.hasOwnProperty(k)) {
			const value = obj[k];
			if (typeof value === 'object' && value !== null) {
				const result = getValueByKey(value, key);
				if (result !== undefined) {
					return result;
				}
			}
		}
	}

	return undefined;
};

export const findKeyInNestedObject = (obj: any, keyToFind: string): any | undefined => {
	if (typeof obj !== 'object' || obj === null) {
		return undefined;
	}

	if (keyToFind in obj) {
		return obj[keyToFind];
	}

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const value = obj[key];
			if (typeof value === 'object' && value !== null) {
				const result = findKeyInNestedObject(value, keyToFind);
				if (result !== undefined) {
					return result;
				}
			}
		}
	}

	return undefined;
};

export const getPath = (rowLength: number, columnLength?: number) => {
	let pathCount = 0;
	const path = [];

	pathCount += rowLength;

	if (columnLength) {
		pathCount += columnLength;
	}

	for (let x = 0; x < pathCount; x++) {
		if (x === pathCount - 1) {
			path.push('value');
		} else {
			path.push('ALL');
		}
	}

	return path;
};

export const getColumnNameByGroupKey = (key: string, columns: string[]) => {
	let name = '';
	columns.forEach((col) => {
		if (key === col.replace(/\s+/g, '')) {
			name = col;
		}
	});

	return name;
};

export const getFormattedTotalsObject = (
	totalsObject: IIndexable,
	unitTypes: TUnitType[],
	facts: DraggableMenuItemData[]
) => {
	Object.keys(totalsObject).forEach((key) => {
		const fact = facts[0];

		if (fact) {
			const unitType = unitTypes.find((type: TUnitType) => type.id == fact?.data?.unitType);

			if (unitType) {
				totalsObject[key] = applyUnitTypeToTotals(
					totalsObject[key],
					unitType,
					fact.data?.numDecimals ? fact.data.numDecimals : 2
				);
			}
		}
	});

	return totalsObject;
};

export const applyUnitTypeToTotals = (obj: any, unitType: TUnitType, numDecimals: number): any => {
	if (Array.isArray(obj)) {
		// If the object is an array, process each element
		return obj.map((item) => applyUnitTypeToTotals(item, unitType, numDecimals));
	} else if (typeof obj === 'object' && obj !== null) {
		// If the object is an object (but not null), process each property
		const newObj: { [key: string]: any } = {};
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				newObj[key] = applyUnitTypeToTotals(obj[key], unitType, numDecimals);
			}
		}
		return newObj;
	} else {
		if (unitType.name.toLowerCase() === 'percentage') {
			obj = (parseFloat(obj) * 100).toFixed(numDecimals);
		} else {
			obj = parseFloat(obj).toFixed(numDecimals);
		}

		// If the object is a number, add the symbol
		return unitType.prefix
			? unitType.prefix + (obj as string)
			: unitType.suffix
			? (obj as string) + unitType.suffix
			: obj;
	}
};
