import AlertTile, { AlertTileProps } from '../../components/alerts/AlertTile';
import { AlertTrendEnum } from '../../enums/alert-trend.enum';

const AlertCardList: AlertTileProps[] = [
	{
		trend: AlertTrendEnum.Up,
		yoyPercentage: -10,
		handle: '@cmoore',
		alertName: 'Turnover Highest In Skill Areas ',
		alertValue: 18,
	},
	{
		trend: AlertTrendEnum.Down,
		yoyPercentage: -16,
		handle: '@cmoore',
		alertName: 'Talent Pool Low In Skill Areas',
		alertValue: 144,
	},
	{
		trend: AlertTrendEnum.Down,
		yoyPercentage: -12,
		handle: '@cmoore',
		alertName: 'Diversity Lacking In Age > 50',
		alertValue: 23,
	},
	{
		trend: AlertTrendEnum.NoChange,
		yoyPercentage: 0,
		handle: '@cmoore',
		alertName: 'Leave Balances Accruing',
		alertValue: 100,
	},
	{
		trend: AlertTrendEnum.Up,
		yoyPercentage: 16,
		handle: '@cmoore',
		alertName: 'Healthcare Cost Exceed Limits',
		alertValue: 6210598,
	},
	{
		trend: AlertTrendEnum.JustInfo,
		handle: '@cmoore',
		alertName: 'EOY Reports Due',
		alertValue: 14,
	},
];

const AlertCardsHomePage = () => {
	return (
		<div className={`alert-tile-container ${!AlertCardList.length ? 'no-alerts' : ''}`}>
			{AlertCardList.length
				? AlertCardList.map((alertProps, index) => (
						<AlertTile
							key={index}
							{...alertProps}
						/>
				  ))
				: 'You have no alerts at this time.'}
		</div>
	);
};

export default AlertCardsHomePage;
