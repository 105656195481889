import { Flex, FlexItem, Text, TextVariants } from '@patternfly/react-core';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { GenericStringKeyValueResponse } from '../api/types';
import { parseFolderSegments } from '../helpers/folder-url-helper';

const BreadCrumbs = () => {
	const pathname = useLocation().pathname;
	const segments = pathname.split('/'); // Split the URL by '/'

	const folderName =
		Number.isInteger(Number(segments[segments.length - 1])) && segments.includes('folder')
			? decodeURI(segments[segments.length - 2])
			: 'Folder';

	const nestedFoldersBreadcrumb = localStorage.getItem('currentNestedFolderPath') ?? 'Create';

	const breadcrumbs = useBreadcrumbs([
		{
			path: '/analyze/dashboards/blank',
			breadcrumb: 'New Dashboard',
		},
		{
			path: '/analyze',
			children: [
				{
					path: 'folder',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{
					path: 'charts/folder/*folderPath',
					breadcrumb: pathname,
				},
				{
					path: 'dataframes',
					breadcrumb: 'Dataframes',
				},
				{
					path: 'dataframes/create',
					breadcrumb: nestedFoldersBreadcrumb,
				},
				{
					path: 'dataframes/edit',
					breadcrumb: null,
				},
				{
					path: 'dataframes/edit/:id',
					breadcrumb: 'Edit',
				},
				{
					path: 'dataframes/folder',
					breadcrumb: null,
				},
				{
					path: 'dataframes/folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'dataframes/folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{
					path: 'charts',
					breadcrumb: 'Charts',
				},
				{
					path: 'charts/build',
					breadcrumb: null,
				},
				{
					path: 'charts/folder',
					breadcrumb: null,
				},
				{
					path: 'charts/folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'charts/folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{
					path: 'dashboards',
					breadcrumb: 'Dashboards',
					children: [
						{
							path: 'view',
							breadcrumb: 'View',
							children: [
								{
									path: ':id',
									breadcrumb: null,
								},
							],
						},
						{
							path: ':dashboardId',
							breadcrumb: 'Edit',
							children: [
								{
									path: 'chart',
									breadcrumb: 'Chart Widget',
									children: [
										{
											path: ':chartId',
											breadcrumb: null,
										},
										{
											path: 'dataframe/:dataframeId',
											breadcrumb: null,
										},
										{
											path: 'key-measure/:measureId',
											breadcrumb: null,
										},
										{
											path: 'key-measure-fact/:measureFactId',
											breadcrumb: null,
										},
									] as BreadcrumbsRoute[],
								},
							] as BreadcrumbsRoute[],
						},
					] as BreadcrumbsRoute[],
				},

				{
					path: 'charts/build/dataframe',
					breadcrumb: null,
				},
				{
					path: 'charts/build/dataframe/:id',
					breadcrumb: nestedFoldersBreadcrumb,
				},
				{
					path: 'charts/build/chart',
					breadcrumb: null,
				},
				{
					path: 'charts/build/chart/:chartId',
					breadcrumb: 'Edit',
				},
				{
					path: 'charts/build/key-measure-fact',
					breadcrumb: null,
				},
				{
					path: 'charts/build/key-measure-fact/:id',
					breadcrumb: null,
				},
				{
					path: 'charts/build/key-measure-fact/:id/:id',
					breadcrumb: 'Create',
				},
			],
		},
		{
			path: '/setup',
			breadcrumb: 'Setup',
		},
		{
			path: '/report',
			children: [
				{
					path: 'folder',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{ path: 'edit', breadcrumb: null },
				{
					path: 'edit/:id',
					breadcrumb: 'Edit',
				},
			],
		},
		{
			path: '/present',
			children: [
				{
					path: 'folder',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{ path: 'edit', breadcrumb: null },
				{
					path: 'edit/:presentationId',
					breadcrumb: 'Edit',
				},
			],
		},
		{
			path: '/analyze/dashboard',
			children: [
				{
					path: ':id',
					breadcrumb: 'View',
				},
			],
		},
	] as BreadcrumbsRoute[]);
	const navigate = useNavigate();

	let hasSetMultiFolderSegments = false;
	let hasSetDynamicFolderSegments = false;

	const CleanFoldersLocalStorage = () => {
		localStorage.removeItem('currentNestedFolderPath');
		localStorage.removeItem('currentSelectedFolderId');
		localStorage.removeItem('prevAnalyzePage');
		localStorage.removeItem('prevDataframePage');
	};

	return (
		<React.Fragment>
			<Flex
				direction={{ default: 'row' }}
				className="breadcrumbs"
			>
				{breadcrumbs.map((item, index) => {
					const isLastItem = index === breadcrumbs.length - 1;
					const secondLastItem =
						item.match.pathname.includes('setup') && breadcrumbs.length >= 4
							? index === breadcrumbs.length - 2
							: false;

					//DYNAMIC BREADCRUMBS START HERE
					if (
						nestedFoldersBreadcrumb.includes('/folder/') &&
						hasSetMultiFolderSegments == false
					) {
						hasSetMultiFolderSegments = true;
						const pathnameToUse = nestedFoldersBreadcrumb ?? pathname;
						const baseSegments: GenericStringKeyValueResponse<string>[] = [
							{ key: 'Home', value: '/' },
							{ key: 'Analyze', value: '/analyze' },
						];

						//CONSTRUCT BASE BREADCRUMBS FIRST
						if (pathnameToUse.includes('/charts/')) {
							baseSegments.push({ key: 'Charts', value: '/analyze/charts' });
						}
						if (pathnameToUse.includes('/dataframes/')) {
							baseSegments.push({
								key: 'Dataframes',
								value: '/analyze/dataframes',
							});
						}

						if (pathnameToUse.includes('/report/')) {
							baseSegments.push({
								key: 'Report',
								value: '/report',
							});
						}

						const breadcrumbElements = baseSegments.map((segment, index) => (
							<Flex
								key={index}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>
									<Text
										component={TextVariants.a}
										onClick={() => {
											setTimeout(() => {
												if (
													window.location.pathname == '/analyze/charts' ||
													window.location.pathname ==
														'/analyze/dataframes'
												) {
													CleanFoldersLocalStorage();
												}
											}, 500);

											navigate(segment.value);
										}}
									>
										{segment.key}
									</Text>
								</FlexItem>
								{index !== baseSegments.length - 1 && <FlexItem>/</FlexItem>}
							</Flex>
						));

						return breadcrumbElements;
					}

					if (hasSetDynamicFolderSegments) {
						return;
					}

					//BUILD DYNAMIC BREADCRUMBS NEXT
					if (hasSetMultiFolderSegments) {
						hasSetDynamicFolderSegments = true;
						const isDataframeChartCreate =
							pathname.includes('/dataframes/create') ||
							pathname.includes('/charts/build');
						const pathnameToUse = isDataframeChartCreate
							? nestedFoldersBreadcrumb ?? item.location.pathname
							: item.location.pathname;
						const folderSegments = parseFolderSegments(pathnameToUse);
						const dynamicFolderBreadcrumbHtml: JSX.Element[] = [];
						dynamicFolderBreadcrumbHtml.push(
							<Flex
								key={-1}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>/</FlexItem>
							</Flex>
						);
						folderSegments.map((segment, idx) => {
							const isLastItem = idx === folderSegments.length - 1;

							const html = (
								<Flex
									key={idx}
									spaceItems={{ default: 'spaceItemsSm' }}
									justifyContent={{ default: 'justifyContentSpaceBetween' }}
								>
									<FlexItem>
										<Text
											component={
												isLastItem &&
												(!nestedFoldersBreadcrumb ||
													!isDataframeChartCreate)
													? TextVariants.p
													: TextVariants.a
											}
											onClick={() => {
												let analyzeEntitySegment = '';
												if (pathname.includes('/dataframes/')) {
													analyzeEntitySegment = 'dataframes';
												}
												if (pathname.includes('/charts/')) {
													analyzeEntitySegment = 'charts';
												}

												setTimeout(() => {
													localStorage.setItem(
														'currentNestedFolderPath',
														window.location.pathname
													);
												}, 500);

												navigate(
													`analyze/${analyzeEntitySegment}/folder/${segment.value}/${segment.id}`
												);
											}}
										>
											{decodeURIComponent(segment.value.toString())}
										</Text>
									</FlexItem>
									{!isLastItem && <FlexItem>/</FlexItem>}
								</Flex>
							);
							dynamicFolderBreadcrumbHtml.push(html);
						});

						if (nestedFoldersBreadcrumb && isDataframeChartCreate) {
							const createBreadcrumbHtml = (
								<Flex
									key={dynamicFolderBreadcrumbHtml.length + 1}
									spaceItems={{ default: 'spaceItemsSm' }}
									justifyContent={{ default: 'justifyContentSpaceBetween' }}
								>
									<FlexItem>/</FlexItem>
									<FlexItem>
										<Text component={TextVariants.p}>Create</Text>
									</FlexItem>
								</Flex>
							);
							dynamicFolderBreadcrumbHtml.push(createBreadcrumbHtml);
						}

						return dynamicFolderBreadcrumbHtml;
					}
					return (
						<Flex
							key={index}
							spaceItems={{ default: 'spaceItemsSm' }}
							justifyContent={{ default: 'justifyContentSpaceBetween' }}
						>
							<FlexItem>
								<Text
									component={isLastItem ? TextVariants.p : TextVariants.a}
									onClick={() => {
										CleanFoldersLocalStorage();
										navigate(item.match.pathname);
									}}
								>
									{item.breadcrumb}
								</Text>
							</FlexItem>
							{!isLastItem && <FlexItem>/</FlexItem>}
						</Flex>
					);
				})}
			</Flex>
		</React.Fragment>
	);
};

export default BreadCrumbs;
