import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderBlank } from '@fortawesome/pro-solid-svg-icons';
import {
	Grid,
	GridItem,
	SearchInput,
	Text,
	TreeView,
	TreeViewDataItem,
} from '@patternfly/react-core';
import { faDisplayChartUpCircleCurrency } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SideBarSearch } from '../types/sidebar';
import { Folder } from '../api/folders/Folder';
import Loader from '../components/util/Loader';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { AlertVariant } from '@patternfly/react-core';
import { FolderTypesEnum } from '../enums/folder-types-enum';
import { faFileChartColumn } from '@fortawesome/pro-regular-svg-icons';
import { Permission } from '../enums/permission.enum';
import { useIsGranted } from '../components/user/ApplicationProvider';

export interface INavigationLink extends TreeViewDataItem {
	name: string;
	iconDefinition?: IconDefinition;
	to?: string;
	children?: INavigationLink[];
	permission?: Permission | Permission[];
}

interface Props {
	sidebarLinks: INavigationLink[];
	folderHeadingTitle: string | null | undefined;
	sidebarSearch?: SideBarSearch;
	folderType?: string;
	reloadFolders?: boolean;
	isFoldersLoading?: boolean;
}

export default function PageNavigationLinks({
	sidebarLinks,
	folderHeadingTitle,
	sidebarSearch,
	folderType,
	reloadFolders,
	isFoldersLoading,
}: Props) {
	const { addToast } = useToast();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>();
	const [activeItems, setActiveItems] = useState<TreeViewDataItem[]>([]);
	const [sidebarFolders, setSidebarFolders] = useState<INavigationLink[]>([]);
	const navigate = useNavigate();
	const location = useLocation();
	const isGranted = useIsGranted();

	const onSelect = (treeViewItem: INavigationLink) => {
		if (treeViewItem && treeViewItem.to) {
			setActiveItems([treeViewItem]);

			if (treeViewItem.id == 'Dashboard') {
				navigate('/analyze/dashboards');
			} else {
				navigate(treeViewItem.to);
			}
		}
		//record folderId chosen from folder side bar for later population
		setTimeout(() => {
			const url = window.location.href;
			const pathname = window.location.pathname;
			const segments = url.split('/');
			const lastSegment = segments[segments.length - 1];
			const folderId = lastSegment;

			if (pathname == '/analyze/charts') {
				localStorage.removeItem('currentSelectedFolderId');
			} else {
				localStorage.setItem('currentSelectedFolderId', folderId);
			}

			if (pathname == '/analyze/dataframes') {
				localStorage.removeItem('prevDataframePage');
			}

			if (url.includes('/report')) {
				if (lastSegment == 'report') {
					localStorage.setItem('prevReportPage', '');
				} else {
					localStorage.setItem('prevReportPage', lastSegment);
				}
			} else if (url.includes('/present')) {
				if (lastSegment == 'present') {
					localStorage.setItem('prevPresentPage', '');
				} else {
					localStorage.setItem('prevPresentPage', lastSegment);
				}
			} else if (url.includes('/folder/') || url.includes('/analyze')) {
				localStorage.setItem('prevAnalyzePage', pathname.replace('/analyze/', ''));
			}
		}, 50);
	};

	const sidebarItems = [];
	const dashboardFolderItems = [];

	useEffect(() => {
		isFoldersLoading = false;
		setSidebarFolders([]);

		sidebarLinks.map((link) => {
			if (location.pathname === link.to) {
				setActiveItems([link]);
				return;
			} else if (link.children) {
				link.children.map((child) => {
					if (location.pathname === child.to) {
						setActiveItems([child]);
					}
				});
			}
		});

		setIsLoading(true);
		const type = formatFolderType(folderType);

		//Get sidebar folders
		Folder.GetAll(['parent'])
			.then((res) => {
				const folders = res
					.filter(
						(folder) =>
							folder.type !== undefined &&
							folder.type === folderType &&
							!folder.parent
					)
					.map((f) => ({
						id: f.name.toLowerCase().replace(' ', '-'),
						name: f.name,
						icon: <FontAwesomeIcon icon={faFolderBlank} />,
						to: type ? `/${type}/folder/${f.name}/${f.id}` : '',
					}));

				setIsLoading(false);
				setSidebarFolders(folders);
			})
			.catch(() => {
				setIsLoading(false);
				addToast(
					'An error occurred while retrieving folders. Please try again later.',
					AlertVariant.danger
				);
			});
	}, [folderType, reloadFolders]);

	useEffect(() => {
		if (sidebarFolders != null) {
			sidebarFolders.map((link) => {
				const decodedPathname = decodeURIComponent(location.pathname);
				if (decodedPathname === link.to) {
					setActiveItems([link]);
					return;
				} else if (link.children) {
					link.children.map((child) => {
						if (decodedPathname === child.to) {
							setActiveItems([child]);
						}
					});
				}
			});
		}
	}, [location, sidebarFolders]);

	useEffect(() => {
		setIsLoading(!isFoldersLoading);
	}, [isFoldersLoading]);

	function formatFolderType(type: string | undefined): string | undefined {
		let folderType = type;

		switch (type) {
			case FolderTypesEnum.Analyze:
				folderType = 'analyze';
				break;
			case FolderTypesEnum.Report:
				folderType = 'report';
				break;
			case FolderTypesEnum.Presentation:
				folderType = 'present';
				break;
		}

		return folderType;
	}

	for (const link of sidebarLinks) {
		let expand = false;

		// Check link for permissions
		if (link.permission && !isGranted(link.permission)) {
			continue;
		}

		// If link has children, check each child for permissions
		if (link.children && link.children.length > 0) {
			const newChildren: INavigationLink[] = link.children.filter((child) => {
				return child.permission ? isGranted(child.permission) : true;
			});

			newChildren.map((child) => {
				if (location.pathname === child.to) {
					expand = true;
				}
			});

			// If the link has children and none of them are visible, skip it
			if (link.children.length !== 0 && newChildren.length === 0) {
				continue;
			}

			link.children = newChildren;
		}

		sidebarItems.push(
			<TreeView
				data={[link]}
				icon={
					link.iconDefinition && (
						<FontAwesomeIcon
							style={{ paddingRight: '0.6em' }}
							icon={link.iconDefinition}
						/>
					)
				}
				allExpanded={expand}
				key={link.name}
				activeItems={activeItems}
				onSelect={(evt, treeViewItem) => {
					onSelect(treeViewItem as INavigationLink);
				}}
			/>
		);
	}

	const type = formatFolderType(folderType);
	const childFolders: INavigationLink[] = [];

	if (sidebarFolders != null && sidebarFolders.length > 0) {
		for (const link of sidebarFolders) {
			let _expand = false;

			if (link.children) {
				link.children.map((child) => {
					if (location.pathname === child.to) {
						_expand = true;
					}
				});
			}

			childFolders.push({
				id: link.id,
				name: link.name,
				to: link.to,
				icon: link.icon && (
					<FontAwesomeIcon
						style={{ paddingRight: '0.6em' }}
						icon={faFolderBlank}
					/>
				),
			});
		}
	}

	if (type === 'analyze' || type === 'report') {
		dashboardFolderItems.push(
			<TreeView
				data={[
					{
						id: folderHeadingTitle,
						name: folderHeadingTitle,
						to: type ? `/${type}` : '',
						children:
							childFolders && childFolders.length > 0 ? childFolders : undefined,
					} as INavigationLink,
				]}
				icon={
					<FontAwesomeIcon
						style={{ paddingRight: '0.6em' }}
						icon={
							type === 'analyze' ? faDisplayChartUpCircleCurrency : faFileChartColumn
						}
					/>
				}
				allExpanded={true}
				key={folderHeadingTitle}
				activeItems={activeItems}
				onSelect={(evt, treeViewItem) => {
					onSelect(treeViewItem as INavigationLink);
					evt.preventDefault();
				}}
			/>
		);
	}

	return (
		<React.Fragment>
			{sidebarSearch && (
				<Grid hasGutter>
					<GridItem
						span={10}
						offset={1}
						key={'sidebar-search'}
					>
						<Text>{sidebarSearch.title}</Text>
						<SearchInput
							placeholder={sidebarSearch.placeholder}
							value={searchTerm}
							onChange={(_event: React.FormEvent<HTMLInputElement>, value: string) =>
								setSearchTerm(value)
							}
							onClear={() => {
								setSearchTerm(undefined);
								sidebarSearch.submit(undefined);
							}}
							onSearch={() => sidebarSearch.submit(searchTerm)}
							aria-label="Search Presentations"
							data-testid="sidebar-search"
						/>
					</GridItem>
					<span style={{ paddingTop: '0rem' }}></span>
					<hr />
					<span style={{ paddingTop: '0.25rem' }}></span>
				</Grid>
			)}
			{sidebarItems}
			{(
				<div>
					<hr style={{ marginTop: '1.25rem' }} />
					<span className="sidebar-spacer"></span>
					{dashboardFolderItems}
				</div>
			) ||
				(isLoading && (
					<div>
						<hr style={{ marginTop: '1.25rem' }} />
						<div className="sidebar-spacer">
							<Loader />
						</div>
					</div>
				))}
		</React.Fragment>
	);
}
