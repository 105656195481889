import { TreeViewDataItem } from '@patternfly/react-core';

export const BuildTreeViewItem = (
	item: { id: number; name: string; display_name?: string | null },
	children: { id: number; name: string; display_name?: string | null }[]
): TreeViewDataItem => {
	return {
		id: item.id.toString(),
		name: item.display_name ? item.display_name : item.name,
		title: item.display_name ? item.display_name : item.name,
		children:
			children && children?.length > 0
				? children.map((child) => {
						return {
							...BuildTreeViewItem(child, []),
							id: BuildSelectorId(item, child),
						};
				  })
				: undefined,
	};
};

export const BuildSelectorId = (
	parent?: { id: number; name: string },
	child?: { id: number; name: string }
): string => {
	const parentId = parent?.id ?? 0;
	const childId = child?.id ?? 0;

	return `${parentId}__${childId}`;
};
