import { Button, Modal, ModalBoxFooter, Text } from '@patternfly/react-core';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: () => void;
};

function DeleteConfirmationModal(props: Props) {
	return (
		<Modal
			title="Delete Confirmation"
			variant="small"
			isOpen={props.isOpen}
			onClose={props.onClose}
			className="delete-modal"
		>
			<hr />
			<br />
			<div className="text-center">
				{' '}
				<Text component="h3">
					Are you sure you would like to proceed with deleting this item?
				</Text>
			</div>
			<br />
			<hr />
			<ModalBoxFooter className="pull-right add-question-footer">
				{' '}
				<Button
					variant="secondary"
					onClick={props.onClose}
				>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={props.onSubmit}
				>
					Delete
				</Button>
			</ModalBoxFooter>
		</Modal>
	);
}

export default DeleteConfirmationModal;
