import { faDashboard } from '@fortawesome/pro-light-svg-icons';
import { CardTypesEnum } from '../../enums/card-types.enum';
import { CardProps } from '../../types/cards/card-props';

export const Dashboards: CardProps[] = [
	{
		title: 'Demographics',
		type: CardTypesEnum.Dashboard,
		url: '#',
		icon: faDashboard,
		isRecent: true,
	},
	{
		title: 'DEI&B',
		type: CardTypesEnum.Dashboard,
		url: '#',
		icon: faDashboard,
		isRecent: true,
	},
	{
		title: 'Engagement',
		type: CardTypesEnum.Dashboard,
		url: '#',
		icon: faDashboard,
		isRecent: true,
	},
	{
		title: 'Movement',
		type: CardTypesEnum.Dashboard,
		url: '#',
		icon: faDashboard,
		isRecent: true,
	},
	{
		title: 'Turnover',
		type: CardTypesEnum.Dashboard,
		url: '#',
		icon: faDashboard,
		isRecent: true,
	},
	{
		title: 'Pay Equity',
		type: CardTypesEnum.Dashboard,
		url: '#',
		icon: faDashboard,
		isRecent: true,
	},
	{
		title: 'Hiring Funnel',
		type: CardTypesEnum.Dashboard,
		url: '#',
		icon: faDashboard,
		isRecent: true,
	},
];
