import React, { useState } from 'react';
import { DashboardWidget } from '../../api/dashbboards/DashboardWidgets';
import ChartView from '../charts/ChartView';
import { Button, InputGroup, Modal, ModalVariant, Text } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/pro-light-svg-icons';
import { useMount } from 'react-use';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './AutoWidget.scss';
import { IIndexable } from '../../types/general';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { DashboardFilter } from '../../api/dashbboards/DashboardFilter';
import { Size } from '../../types/databuilder/databuilder';
library.add(far, fas, fal);

type Props = {
	widget: DashboardWidget;
	selectedDate?: TNewDateRange;
	removeWidget?: (widget: DashboardWidget) => void;
	updateWidget?: (widget: DashboardWidget) => void;
	handleChartEditClick?: (widget: DashboardWidget) => void;
	setEditingId?: (id?: number) => void;
	filters?: DashboardFilter[];
	isEdit?: boolean;
	size?: Size;
};

export type TextObjectType = {
	type: 'p' | 'h2' | 'h3';
	value: string;
};

const AutoWidget = (props: Props) => {
	const {
		widget,
		selectedDate,
		removeWidget,
		updateWidget,
		handleChartEditClick,
		setEditingId,
		filters,
		isEdit,
		size,
	} = props;
	const [editing, setEditing] = useState<boolean>(false);
	const [editState, setEditState] = useState<EditorState>(EditorState.createEmpty());
	let component: React.ReactNode = <></>;

	useMount(() => {
		setInitialState();
	});

	const setInitialState = () => {
		let html = '';
		if (widget.widget_type === 'text' && widget.content) {
			if (isJsonString(widget.content)) {
				const jsonObject = JSON.parse(widget.content) as TextObjectType;
				html = jsonObject.value;
			} else {
				html = widget.content;
			}
		}
		setEditState(getEditorStateFromString(html));
	};

	const getEditorStateFromString = (value: string) => {
		let currentState = EditorState.createEmpty();
		const contentBlock = htmlToDraft(value);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			currentState = EditorState.createWithContent(contentState);
		}
		return currentState;
	};

	const isJsonString = (jsonString: string): boolean => {
		try {
			const o = JSON.parse(jsonString) as IIndexable;

			// Handle non-exception-throwing cases:
			// Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
			// but... JSON.parse(null) returns null, and typeof null === "object",
			// so we must check for that, too. Thankfully, null is falsey, so this suffices:
			if (o && typeof o === 'object') {
				return true;
			}
		} catch (e) {
			return false;
		}

		return false;
	};
	const toggleEdit = () => {
		setEditing(!editing);
		if (setEditingId) {
			setEditingId(!editing ? widget.id : undefined);
		}
	};

	const onEditorStateChange = (editorState: EditorState) => {
		console.log('EditorState', {
			editorState,
			content: editorState.getCurrentContent(),
			js: editorState.toJS(),
		});
		setEditState(editorState);
	};

	const getTextComponent = () => {
		return (
			<>
				<Text
					id={`autowidget-text-${widget.id ?? '0'}`}
					className="autowidget-text"
					dangerouslySetInnerHTML={{
						__html: editState
							? draftToHtml(
									convertToRaw(
										getEditorStateFromString(
											widget.content ?? ''
										).getCurrentContent()
									)
							  )
							: '',
					}}
				></Text>
				<span className="edit-span">
					<FontAwesomeIcon
						icon={faPencil}
						className="edit-icon"
						size="xs"
						onClick={(e) => {
							e.stopPropagation();
							toggleEdit();
						}}
					/>
				</span>
				<span className="delete-span">
					<FontAwesomeIcon
						icon={faTrash}
						className="delete-icon"
						size="xs"
						onClick={(e) => {
							e.stopPropagation();
							removeWidget && widget && removeWidget(widget);
						}}
					/>
				</span>
				<Modal
					aria-label="Edit Widget Modal"
					isOpen={editing}
					variant={ModalVariant.small}
					showClose={false}
					actions={[
						<Button
							key="confirm"
							variant="primary"
							onClick={() => {
								console.log('SAVE', {
									value: draftToHtml(convertToRaw(editState.getCurrentContent())),
								});
								widget.id &&
									updateWidget &&
									updateWidget({
										...widget,
										content: editState
											? draftToHtml(
													convertToRaw(editState.getCurrentContent())
											  )
											: '',
									});
								toggleEdit();
							}}
						>
							Save
						</Button>,
						<Button
							key="cancel"
							variant="link"
							onClick={() => {
								setInitialState();
								toggleEdit();
							}}
						>
							Cancel
						</Button>,
					]}
				>
					<InputGroup>
						<Editor
							editorState={editState}
							toolbar={{
								options: ['inline', 'fontFamily', 'fontSize', 'textAlign'],
								inline: {
									options: ['bold', 'italic', 'underline', 'strikethrough'],
								},
								fontFamily: {
									options: [
										'Arial',
										'Georgia',
										'Impact',
										'Tahoma',
										'Times New Roman',
										'Verdana',
									],
								},
								fontSize: {
									options: [
										8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
									],
								},
							}}
							onEditorStateChange={onEditorStateChange}
						/>
					</InputGroup>
				</Modal>
			</>
		);
		// }
	};

	const getChartComponent = () => {
		return (
			<>
				<ChartView
					chartId={widget.chart?.toString() ?? '0'}
					selectedDate={selectedDate}
					allowClickNavigate
					filters={filters}
					isEdit={isEdit}
					transparentBackground={true}
					width={size ? size.width : ''}
					height={size ? size.height : ''}
					delayedDisplayTime={50}
				/>
				<span className="edit-span">
					<FontAwesomeIcon
						icon={faPencil}
						className="edit-icon"
						size="xs"
						onClick={() => {
							handleChartEditClick && handleChartEditClick(widget);
						}}
					/>
				</span>
				<span className="delete-span">
					<FontAwesomeIcon
						icon={faTrash}
						className="delete-icon"
						size="xs"
						onClick={() => removeWidget && widget && removeWidget(widget)}
					/>
				</span>
			</>
		);
	};

	switch (widget.widget_type) {
		case 'text':
			component = getTextComponent();
			break;
		case 'chart':
			component = getChartComponent();
			break;
		default:
			break;
	}
	return component;
};

export default AutoWidget;
