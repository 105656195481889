import { faChartLineUp, faChartPie, faTable } from '@fortawesome/pro-light-svg-icons';
import { CardTypesEnum } from '../../enums/card-types.enum';
import { CardProps } from '../../types/cards/card-props';
import { faChartBar, faChartTreeMap } from '@fortawesome/pro-thin-svg-icons';
import { faChartPyramid } from '@fortawesome/pro-regular-svg-icons';

export const ChartsAndDataframes: CardProps[] = [
	{
		url: `/analyze/${CardTypesEnum.Chart}/1`,
		type: CardTypesEnum.Chart,
		icon: faChartBar,
		title: 'Headcount Snapshot',
		isRecent: true,
		isFavorite: true,
	},
	{
		url: `/analyze/${CardTypesEnum.DataFrame}/2`,
		type: CardTypesEnum.DataFrame,
		icon: faTable,
		title: 'Turnover (Rolling 12 Mth)',
		isFavorite: true,
	},
	{
		url: `/analyze/${CardTypesEnum.DataFrame}/3`,
		type: CardTypesEnum.DataFrame,
		icon: faTable,
		title: 'Cost of Workforce',
		isNew: true,
		isRecent: true,
	},
	{
		url: `/analyze/${CardTypesEnum.Chart}/4`,
		type: CardTypesEnum.Chart,
		icon: faChartPie,
		title: 'Diversity by Ethnicity',
		isRecent: true,
	},
	{
		url: `/analyze/${CardTypesEnum.Chart}/5`,
		type: CardTypesEnum.Chart,
		icon: faChartLineUp,
		title: 'Open Positions',
		isRecent: true,
	},
	{
		url: `/analyze/${CardTypesEnum.DataFrame}/6`,
		type: CardTypesEnum.DataFrame,
		icon: faTable,
		title: 'New Hires',
		isNew: true,
	},
	{
		url: `/analyze/${CardTypesEnum.Chart}/7`,
		type: CardTypesEnum.Chart,
		icon: faChartPie,
		title: 'Terminations by Reason',
		isFavorite: true,
	},
	{
		url: `/analyze/${CardTypesEnum.DataFrame}/8`,
		type: CardTypesEnum.DataFrame,
		icon: faTable,
		title: 'Diversity',
		isFavorite: true,
	},
	{
		url: `/analyze/${CardTypesEnum.DataFrame}/9`,
		type: CardTypesEnum.DataFrame,
		icon: faTable,
		title: 'Demographics',
		isNew: true,
	},
	{
		url: `/analyze/${CardTypesEnum.Chart}/10`,
		type: CardTypesEnum.Chart,
		icon: faChartTreeMap,
		title: 'Turnover Factors',
		isRecent: true,
	},
	{
		url: `/analyze/${CardTypesEnum.Chart}/10`,
		type: CardTypesEnum.Chart,
		icon: faChartTreeMap,
		title: 'Retirement Risk',
		isRecent: true,
	},
	{
		url: `/analyze/${CardTypesEnum.Chart}/10`,
		type: CardTypesEnum.Chart,
		icon: faChartTreeMap,
		title: 'Flight Risk Indicators',
		isRecent: true,
	},
	{
		url: `/analyze/${CardTypesEnum.DataFrame}/11`,
		type: CardTypesEnum.DataFrame,
		icon: faTable,
		title: 'Applicants',
	},
	{
		url: `/analyze/${CardTypesEnum.DataFrame}/12`,
		type: CardTypesEnum.DataFrame,
		icon: faTable,
		title: 'Span of Control',
		isNew: true,
	},
	{
		url: `/analyze/${CardTypesEnum.Chart}/13`,
		type: CardTypesEnum.Chart,
		icon: faChartPie,
		title: 'Headcount by Age Band',
	},
	{
		url: `/analyze/${CardTypesEnum.DataFrame}/14`,
		type: CardTypesEnum.DataFrame,
		icon: faTable,
		title: 'Payroll',
	},
	{
		url: `/analyze/${CardTypesEnum.DataFrame}/15`,
		type: CardTypesEnum.DataFrame,
		icon: faTable,
		title: 'Labor Hours',
		isNew: true,
	},
	{
		url: `/analyze/${CardTypesEnum.Chart}/16`,
		type: CardTypesEnum.Chart,
		icon: faChartPyramid,
		title: 'Promotions by Reason',
	},
	{
		url: `/analyze/${CardTypesEnum.DataFrame}/17`,
		type: CardTypesEnum.DataFrame,
		icon: faTable,
		title: 'Employment Status',
	},
];
