import {
	Button,
	EmptyState,
	EmptyStateBody,
	EmptyStatePrimary,
	Flex,
	FlexItem,
	Text,
} from '@patternfly/react-core';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { ReactElement, useEffect, useState } from 'react';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import MainSubNavContext from '../sub-nav-contexts/main-sub-nav-context';
import { useOutletContext } from 'react-router-dom';
import { OutletContext } from '../../layout/Layout';
import NoAccessImg from './../../img/403.png';
import { SubNavContext } from '../sub-nav-contexts/sub-nav-context';
import { useMount } from 'react-use';
import { RecentTypesEnum } from '../../enums/recent-types.enum';
import { Recent } from '../../api/recents/recents';

function RestrictedAccess(): ReactElement {
	const navigate = useNavigate();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [subNavContext, setSubNavContext] = useState<SubNavContext>(MainSubNavContext);

	useMount(() => {
		void Recent.GetAll(RecentTypesEnum.Charts).then((response) => {
			setSubNavContext({
				...subNavContext,
				reportSnapshot: {
					title: `From Your Recents: ${response.length ? response[0].name ?? '' : ''}`,
					snapshotText: '',
					...(response.length && { chartId: response[0].entity_id }),
				},
			});
		});
	});

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle=""
					pageDescription=""
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
					subNavContext={subNavContext}
					collapseByDefault={true}
				/>
			),
			sidebarContext: null,
			additionalClassNames: 'home-page-subbar no-title',
			hideLeftSideBar: true,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded, subNavContext]);

	return (
		<EmptyState>
			<EmptyStateBody data-testid="empty-results-body">
				<div className="text-center">
					<img
						width={800}
						height={600}
						style={{ maxHeight: '100%' }}
						src={NoAccessImg}
					></img>
				</div>
			</EmptyStateBody>
			<EmptyStatePrimary data-testid="empty-results-primary">
				<Button
					variant="primary"
					className="btn-home-back"
					onClick={() => {
						navigate('/');
					}}
				>
					<Flex>
						<FlexItem>
							<FontAwesomeIcon icon={faArrowLeft} />
						</FlexItem>
						<FlexItem>
							<Text>GO TO HOMEPAGE</Text>
						</FlexItem>
					</Flex>
				</Button>
			</EmptyStatePrimary>
		</EmptyState>
	);
}

export default RestrictedAccess;
