import React, { ReactElement } from 'react';
import './LambdaInsights.scss';
import { LambdaInsightsResponse } from '../../api/types';

export type props = {
	insights: LambdaInsightsResponse;
	hasBackground: boolean;
};

const LambdaInsights = (props: props): ReactElement => {
	const { insights, hasBackground } = props;

	return (
		<div className={`lambda-insights-container ${hasBackground ? 'insights-background' : ''}`}>
			<div className={'insights'}>
				<div className={'header'}>
					<b>Insights</b>
				</div>
				{insights.insights.map((insight) => {
					return <div className={'insight-item'}>{insight}</div>;
				})}
			</div>
			<div className={'actions'}>
				<div className={'header'}>
					<b>Actions</b>
				</div>
				{insights.actions.map((action) => {
					return <div className={'insight-item'}>{action}</div>;
				})}
			</div>
		</div>
	);
};

export default LambdaInsights;
